import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Nav.scss';
import NavItem from "./NavItem/NavItem"
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import character from '../../Images/character.svg';
import { useAuth } from '../../Utilities/Auth/UseAuth';
import { abbreviateText } from '../../Utilities/Format';
import { useTranslation } from 'react-i18next';

type Props = {
    open: boolean,
    onMenuHide: () => void
}

export default function Nav({ open, onMenuHide }: Props)
{
    const { t } = useTranslation("nav");
    const navigate = useNavigate();
    const { boardName } = useAuth();

    const handleItemClick = (route: string) => {
        navigate(route);
        onMenuHide();
    };

    return (
        <nav className={open ? "open" : ""}>
            <div className="container">
                <header>
                    <div className="container">
                        <h2>{t("title")}</h2>
                        <img src={character} alt="" className="character" onClick={() => handleItemClick("/settings/user")} />
                    </div>
                </header>
                <div className="items top">
                    <NavItem icon={<FontAwesomeIcon icon="chart-line" />} text={t("summary")} onClick={() => handleItemClick("/summary")} />
                    <NavItem icon={<FontAwesomeIcon icon="right-left" />} text={t("payments")} onClick={() => handleItemClick("/payments")}  />
                    <NavItem icon={<FontAwesomeIcon icon="calendar-alt" />} text={t("subscriptions")} onClick={() => handleItemClick("/subscriptions")}  />
                    <NavItem icon={<FontAwesomeIcon icon="hand-holding-usd" />} text={t("income")} onClick={() => handleItemClick("/income")}  />
                    <NavItem icon={<FontAwesomeIcon icon="balance-scale-left" />} text={t("debt")} onClick={() => handleItemClick("/debt")}  />
                    <NavItem icon={<FontAwesomeIcon icon="list" />} text={t("categories")} onClick={() => handleItemClick("/categories")}  />
                    <NavItem icon={<FontAwesomeIcon icon="cog" />} text={t("settings")} onClick={() => handleItemClick("/settings/board")}  />
                </div>
                <div className="items bottom">
                    <NavItem 
                        icon={abbreviateText(boardName, 2).toUpperCase()} 
                        text={boardName} 
                        onClick={() => handleItemClick("/boards")}  />
                    <NavItem 
                        icon={<FontAwesomeIcon icon="sign-out-alt" />} 
                        text={t("logout")}
                        onClick={() => handleItemClick("/logout")}  />
                </div>
            </div>
        </nav>
    );
}
