import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"

export type Errors = Array<Error>

export type Error = FormError | MessageError

export type FormError = {
    field: string,
    message: string
}

export type MessageError = string

type FormErrorProps = {
    field: string,
    errors: Errors
}

const isFormError = (error: Error): error is FormError => {
    return (error as FormError).field !== undefined;
};

type MessageTranslation = {
    icon: IconProp,
    text: string
};

const messages: Record<string, Partial<MessageTranslation>> = {
    // generic
    "empty": { icon: "text-width" },

    // board
    "board-name-tooLong": {},

    // debt
    "debt-amount-range": {},
    "debt-userLended-sameNames": {},

    // categories
    "category-name-tooLong": {},

    // payments
    "payment-name-tooLong": {},
    "payment-note-tooLong": {},
    "payment-amount-range": {},
    "payment-category-notFound": {},
    "payment-interval-range": { icon: "calendar-alt" },
    "payment-interval-tooLarge": { icon: "calendar-alt" },
    "payment-interval-whole": { icon: "calendar-alt" },

    // registration
    "registration-repeatPassword-mismatch": { icon: "equals" },
    "registration-name-tooShort": { icon: "text-width" },
    "registration-name-tooLong": { icon: "text-width" },
    "registration-mail-tooShort": { icon: "text-width" },
    "registration-mail-tooLong": { icon: "text-width" },
    "registration-mail-accountExists": {},
    "registration-name-accountExists": {},
    "registration-mail-invalidFormat": {},
    "registration-password-tooShort": { icon: "text-width" },
    "registration-password-tooLong": { icon: "text-width" },

    // change password
    "changePassword-passwordNewRepeat-mismatch": { icon: "equals" },
    "changePassword-passwordNew-tooShort": {},
    "changePassword-passwordNew-tooLong": {},
    "changePassword-passwordOld-wrongPassword": {},

    // change mail
    "changeMail-password-wrongPassword": {},
    "changeMail-mail-tooShort": { icon: "text-width" },
    "changeMail-mail-tooLong": { icon: "text-width" },
    "changeMail-mail-invalidFormat": {},

    // change currency
    "changeCurrency-currency-tooLong": { icon: "text-width" },

}

const translateMessage = (message: string, t: (s: string) => string): MessageTranslation => {
    if(messages[message] !== undefined)
    {
        const msg = messages[message];
        return {
            icon: msg.icon !== undefined ? msg.icon : "triangle-exclamation",
            text: t("form." + message)
        };
    }

    return {
        icon: "triangle-exclamation",
        text: "Error: " + message
    };
};

export const FormError = ({ field, errors }: FormErrorProps) => {
    
    const { t } = useTranslation("errors");
    const item = errors.find(i => isFormError(i) && i.field === field);
    if(!item || !isFormError(item)) return <></>;

    const { icon, text } = translateMessage(item.message, t);

    return <div className="formError">
        <FontAwesomeIcon icon={icon} />
        <div className="message">{text}</div>
    </div>;
};