import { useState } from 'react';
import { Category, OneTimePayment, useGetOneTimePaymentsApi } from '../../Utilities/Api';
import Item from './Item/Item'
import './Payments.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, EmptyList } from '../../Components/UI';
import Modal, { Form } from './Modal/Modal';
import { formatDate } from '../../Utilities/Date';
import { groupBy } from '../../Utilities/Array';
import { CurrencyContext } from '../../Utilities/Context/CurrencyContext';
import { useTranslation } from 'react-i18next';

export default function Payments()
{
  const { t } = useTranslation("payments");
  const { response, refresh } = useGetOneTimePaymentsApi();
  const [modal, setModal] = useState<Form>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const handleNewClick = () => {
    setModalVisible(true);
    setModal(undefined);
  };

  const handleClose = (shouldRefresh: boolean) => {
    setModalVisible(false);
    if(shouldRefresh) refresh();
  };

  const handleItemClick = (item: OneTimePayment) => {
    setModalVisible(true);
    setModal({
      id: item.id,
      note: item.note,
      amount: { text: item.amount.toString(), number: item.amount },
      category: item.category?.id || "",
      type: item.type
    });
  };

  // group by date
  const groups = groupBy(response.items, item => formatDate(item.dateCreated));

  return (
    <div className="content">
      <CurrencyContext.Provider value={response.currency}>
        <header>
          <h2>{t('title')}</h2>
          <Button 
            size="small"
            icon={<FontAwesomeIcon icon="plus"/>} 
            onClick={handleNewClick} />
        </header>
        <div className="payments">
          <div className="container">
            {response.items.length === 0
              ? <EmptyList text={t('empty.text')} action={{ text: t('empty.action'), callback: handleNewClick}}/>
              : groups.map(({condition, items}) => (
                  <div className="group" key={condition}>
                    <h2>{condition}</h2>
                    <div className="items">
                      {items.map((item, index) => 
                        <Item 
                          key={index}
                          onClick={() => handleItemClick(item)}
                          members={response.members}
                          {...item} />)}
                    </div>
                  </div>
                ))}
          </div>
        </div>
        <Modal visible={modalVisible} onClose={handleClose} values={modal} />
      </CurrencyContext.Provider>
    </div>
  );
}
