import { useEffect, useState } from "react"
import { useApi } from "../Api"
import { useAuth } from "../../Auth/UseAuth"
import { RecurringExpensePayment } from "../Types/Payment"

type Response = {
    currency: string,
    items: Array<RecurringExpensePayment>
}

export const useGetSubscriptionsApi = () => {
    const { loading, send: sendBase } = useApi();
    const [ response, setResponse ] = useState<Response>({ currency: "", items: [] });
    const [ error, setError ] = useState({ });
    const { boardId } = useAuth();

    const refresh = () =>
        sendBase<Response>("payments/recurring/expense/" + boardId, "GET", {})
            .then(setResponse)
            .catch(setError);

    useEffect(() => {
        refresh();
    }, [])

    return {
        loading,
        response,
        error, 
        refresh
    }
}