import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import { SummaryGroupAreaChart } from "../../../Utilities/Api";
import "./DisplayAreaChart.scss";
import { useContext } from "react";
import { CurrencyContext } from "../../../Utilities/Context/CurrencyContext";

type Props = SummaryGroupAreaChart

export default function DisplayAreaChart({ data }: Props)
{
  const currency = useContext(CurrencyContext);

  let items = data.groups.map(group => ({
    name: group.label,
    value: group.values[0],
    currency
  }));

  // if there is only one node
  // spread graph
  if(items.length === 1)
    items = [ items[0], items[0] ];

  return <div className="displayAreaChart">
    <div className="graph">
      <ResponsiveContainer width="100%" height="100%" minHeight={150}>
        <AreaChart
          width={320}
          height={100}
          data={items}
        >
          <Tooltip
            content={<CustomTooltip active={undefined} payload={undefined} label={undefined} />}/>
          <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="value"
            stroke="#0064E8"
            strokeLinecap="round"
            strokeWidth={6}
            fill="#E6F0FD"
            fillOpacity={1} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
    <div className="total">
      <div className="sum">
        <div className="value">{data.groups.length > 0 ? data.groups[data.groups.length - 1].values[0] : 0}</div>
        <div className="unit">{currency}</div>
      </div>
    </div>
  </div>
};

type CustomTooltipType = {
  active?: boolean,
  payload: any,
  label?: string
};

const CustomTooltip = ({ active, payload, label }: CustomTooltipType) => {
  if (active && payload && payload.length) {
    return (
      <div className="customTooltip" tabIndex={-1}>
        <div className="label">{payload[0].payload.name}</div>
        <div className="value">{payload[0].payload.value} {payload[0].payload.currency}</div>
      </div>
    );
  }

  return null;
};