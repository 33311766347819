import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Colors } from "../../Utilities/Options/Colors";
import { Category } from "../../Utilities/Api";
import './CategoryChip.scss';

type Style = "normal" | "text";

type Props = Omit<Category, "id"> & {
  style?: Style
};

export default function CategoryChip({ name, icon, color, style="normal" }: Props)
{
  const c = Colors.find(({ name: n }) => n === color);

  return (
    <div className="categoryChip" style={{ 
        color: c?.color,
        background: style === "normal" ? c?.background : undefined
    }}>
        <div className="icon">
            <FontAwesomeIcon icon={icon as IconProp} />
        </div>
        <div className="name">{name}</div>
    </div>
  );
}