import React from 'react'
import { useState } from 'react';
import { RecurringIncomePayment, useGetIncomeApi } from '../../Utilities/Api';
import Item from './Item/Item'

import './Income.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, EmptyList } from '../../Components/UI';
import { Form, Modal } from './Modal/Modal';
import { CurrencyContext } from '../../Utilities/Context/CurrencyContext';
import { useTranslation } from 'react-i18next';

export default function Income()
{
  const { t } = useTranslation("income");
  const { response, refresh } = useGetIncomeApi();
  const [expanded, setExpanded] = useState(-1);
  const [modal, setModal] = useState<Form>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const handleNewClick = () => {
    setModalVisible(true);
    setModal(undefined);
  };

  const handleClose = (shouldRefresh: boolean) => {
    setModalVisible(false);
    if(shouldRefresh) {
      setExpanded(-1);
      refresh();
    }
  };

  const handleEditClick = (item: RecurringIncomePayment) => {
    setModalVisible(true);
    setModal({
      id: item.id,
      name: item.name,
      icon: item.icon,
      color: item.color,
      amount: { text: item.amount.toString(), number: item.amount },
      category: item.category?.id || ""
    });
  };

  return (
    <div className="content">
      <CurrencyContext.Provider value={response.currency}>
        <header>
          <h2>{t('title')}</h2>
          <Button 
            size="small"
            icon={<FontAwesomeIcon icon="plus"/>} 
            onClick={handleNewClick} />
        </header>
        <div className="income">
          <div className="container">
          {response.items.length === 0
              ? <EmptyList text={t('empty.text')} action={{ text: t('empty.action'), callback: handleNewClick}}/>
              : response.items.map((item, index) => 
                <Item 
                  key={index} 
                  expanded={index === expanded}  
                  onClick={() => setExpanded(index === expanded ? -1 : index)}
                  onEditClick={() => handleEditClick(item)}
                  {...item} />
              )}
          </div>
        </div>
        <Modal visible={modalVisible} onClose={handleClose} values={modal} />
      </CurrencyContext.Provider>
    </div>
  );
}
