import React from 'react';
import './Input.scss';

export type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  
}

export const Input = ({ value, ...props }: Props) => {
  const className = `input`;
  return (
    <input 
      className={className} 
      value={value}
      {...props}
    />
  );
}
