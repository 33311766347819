const functions: Record<string, (n1: string, n2: string) => string> = {
    "+": (n1: string, n2: string) => (Number(n1) + Number(n2)).toString(),
    "-": (n1: string, n2: string) => (Number(n1) - Number(n2)).toString(),
    "*": (n1: string, n2: string) => (Number(n1) * Number(n2)).toString(),
    "/": (n1: string, n2: string) => (Number(n1) / Number(n2)).toString()
}

export const parseNumber = (text: string): number => {
    if(text.length === 0) return 0;
    
    const parts = text.split(/([\+\-\*\/])/g);
    
    if(parts.length > 1)
    {
        // multiplication and division
        for(let i = 1; i < parts.length; i += 2)
        {
            if("*/".includes(parts[i]))
            {
                parts[i - 1] = functions[parts[i]](parts[i - 1], parts[i + 1] || "1");
                parts.splice(i, 2);
                i -= 2;
            }
        }

        // addition subtraction
        for(let i = 1; i < parts.length; i += 2)
        {
            parts[i - 1] = functions[parts[i]](parts[i - 1], parts[i + 1] || "0");
            parts.splice(i, 2);
            i -= 2;
        }
    }

    return Math.ceil(Number(parts[0]) * 10000) / 10000;
};