export const ToText = (months: number): {
  text: string,
  number: string,
  unit: string,
  months: number
} => {
  const convertToYears = months % 12 === 0 && months !== 0;

  const unit = convertToYears
    ? (months / 12 === 1 ? "Year" : "Years")
    : (months === 1 ? "Month" : "Months");

  const number = convertToYears
    ? months / 12
    : months;

  return {
    text: number + " " + unit,
    number: number.toString(),
    unit,
    months
  }
};