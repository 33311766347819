import { useEffect, useState } from "react"
import { useApi } from "../Api"
import { useAuth } from "../../Auth/UseAuth"
import { Member } from "../Types/Member"

type Response = {
    members: Array<Member>,
    current?: Member
}

export const useGetMembersApi = () => {
    const { loading, send: sendBase } = useApi();
    const [ response, setResponse ] = useState<Response>({ members: [] });
    const [ error, setError ] = useState({ });
    const { boardId } = useAuth();

    const refresh = () =>
        sendBase<Response>("members/" + boardId, "GET", { })
            .then(setResponse)
            .catch(setError);

    useEffect(() => {
        refresh();
    }, []);

    return {
        loading,
        response,
        error,
        refresh
    }
}