import { useNavigate, useParams } from "react-router-dom";
import { LoaderIcon, Spinner } from "../../Components/UI"
import "./AcceptInvite.scss"
import { useEffect } from "react";
import { useAcceptBoardInviteApi } from "../Api";
import { useAuth } from "../Auth/UseAuth";

export const AcceptInvite = () => {
    const { send, loading, response, error } = useAcceptBoardInviteApi();
    const { setBoard } = useAuth();
    const navigate = useNavigate();
    const { code } = useParams();

    useEffect(() => {
        if(code && code.length > 0 && !loading)
            send({ code })
    }, [code]);

    useEffect(() => {
        if(response.board)
        {
            setBoard(response.board.id, response.board.name);
            navigate("/summary");
        }
        console.log(response);
    }, [response]);
    
    return <div className="acceptInvite">
        <Spinner><LoaderIcon size={32} color="#0064E8"/></Spinner>
    </div>
};