import React from 'react';
import './Button.scss';

type Mode = "primary" | "secondary" | "link"

export type ButtonColor = "primary" | "danger" | "success"

type Size = "large" | "medium" | "small"

type Props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  mode?: Mode,
  color?: ButtonColor,
  size?: Size,
  icon?: React.ReactNode
}

export const Button = ({ mode="primary", color="primary", size="medium", icon, children,  ...props }: Props) => {
  const flagNoContent = children ? "" : "flag_noContent"
  const className = `button mode_${mode} color_${color} size_${size} ${flagNoContent}`;
  return (
    <button className={className} {...props}>
      {icon}
      {children}
    </button>
  );
}
