import { useApi } from "../Api"

type Props = {
    passwordOld: string,
    passwordNew: string
}

type Response = {}

export const useChangePasswordApi = () => {
    const { loading, send: sendBase } = useApi();

    const send = (props: Props) => 
        sendBase<Response>("changePassword", "PUT", props);

    return {
        loading,
        send
    };
}