import { UseBoundStore, create } from "zustand"
import { persist } from 'zustand/middleware'

export type AuthType = {
    accessToken: string,
    boardId: string,
    boardName: string,
    setAccessToken: (accessToken: string) => void,
    setBoard: (id: string, name: string) => void,
    loggedIn: () => boolean
}

export const useAuth = create<AuthType>()(persist(
    (set, get) => ({
        accessToken: "",
        boardId: "",
        boardName: "",
        setAccessToken: (accessToken: string) => set(() => ({ accessToken })),
        setBoard: (id: string, name: string) => set(() => ({ boardId: id, boardName: name })),
        loggedIn: () => get().accessToken.length > 0
    }),
    {
        name: "authStore"
    }
));