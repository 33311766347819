export const ArrowVerticalIcon = ({ color="#000", size=16 }) => {
    return (
        <svg 
            id="Layer_1" 
            data-name="Layer 1" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 16 32"
            style={{ width: size/2, height: size}}>
            <path 
                d="M10.32,18.87a.6.6,0,0,1,.79.34l2.58,6.49a.61.61,0,0,1-.35.79L6.86,29.06a.61.61,0,0,1-.79-.34L5.77,28a.62.62,0,0,1,.36-.8l4-1.46.81-1.87L9.24,20a.61.61,0,0,1,.34-.8Z"
                style={{
                    fill: color
                }}/>
            <path 
                d="M10.82,26.06a1,1,0,0,1-.33-.06,12.24,12.24,0,0,1-8.2-12.16A12.21,12.21,0,0,1,11,2.94a1,1,0,0,1,1.25.67,1,1,0,0,1-.67,1.24A10.21,10.21,0,0,0,4.29,14a10.22,10.22,0,0,0,6.86,10.16,1,1,0,0,1-.33,1.95Z"
                style={{
                    fill: color
                }}/>
        </svg>
    )
}