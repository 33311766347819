import { useAuth } from "../../Auth/UseAuth"
import { useApi } from "../Api"

type Props = {
    id?: string
}

type Response = {}

export const useRemoveMemberApi = () => {
    const { loading, send: sendBase } = useApi();
    const { boardId } = useAuth();

    const send = (props: Props) => 
        sendBase<Response>("members/" + boardId, "DELETE", props);

    return {
        loading,
        send
    };
}