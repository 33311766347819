export const abbreviateText = (text: string, maxLength?: number): string => {
    const abbreviation = text
        .split(" ")
        .filter(word => word.length > 0)
        .map(word => word[0])
        .join("");

    if(maxLength !== undefined && abbreviation.length > maxLength)
        return abbreviation.substring(0, maxLength);

    return abbreviation;
}