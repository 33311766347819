import { useAuth } from "../../Auth/UseAuth"
import { useApi } from "../Api"

type Props = {
    name: string,
    icon: string,
    color: string
}

type Response = {}

export const useNewCategoryApi = () => {
    const { loading, send: sendBase } = useApi();
    const { boardId } = useAuth();

    const send = (props: Props) => 
        sendBase<Response>("categories/" + boardId, "POST", props);

    return {
        loading,
        send
    };
}