export const FlagCZIcon = ({ size=16 }) => {
    return (
        <svg 
            id="Layer_1" 
            data-name="Layer 1" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 16 16"
            style={{ width: size, height: size}}>
            <path style={{fill: "#f9f9f9"}} d="M2.34,2.34V8H16A8,8,0,0,0,2.34,2.34Z"/>
            <path style={{fill: "#ed4c5c"}} d="M2.34,8v5.66h0A8,8,0,0,0,16,8Z"/>
            <path style={{fill: "#428bc1"}} d="M2.34,2.34a8,8,0,0,0,0,11.32L8,8Z"/>
        </svg>
    )
}