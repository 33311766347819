import { useEffect, useState } from "react"
import { useApi } from "../Api"
import { Board } from "../Types/Board"

type Response = {
    items: Array<Board>
}

export const useGetBoardsApi = () => {
    const { loading, send: sendBase } = useApi();
    const [ response, setResponse ] = useState<Response>({ items: [] });
    const [ error, setError ] = useState({ });

    const refresh = () =>
        sendBase<Response>("boards", "GET", {})
            .then(setResponse)
            .catch(setError);

    useEffect(() => {
        refresh();
    }, []);

    return {
        loading,
        response,
        error,
        refresh
    };
}