import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input } from "../../../../Components/UI";
import { useGetMembersApi } from "../../../../Utilities/Api";
import InviteModal from "./InviteModal";
import { useState } from "react";
import KickModal from "./KickModal";
import LeaveModal from "./LeaveModal";
import DeleteModal from "./DeleteModal";
import { useTranslation } from "react-i18next";
import ChangeCurrency from "./ChangeCurrency";

export default function Board()
{
  const { t } = useTranslation("settings");
  const [inviteModalVisible, setInviteModalVisible] = useState<boolean>(false);
  const [kickModalVisible, setKickModalVisible] = useState<boolean>(false);
  const [kickModalValues, setKickModalValues] = useState({ id: "", name: "" });
  const [leaveModalVisible, setLeaveModalVisible] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const { response, refresh } = useGetMembersApi();

  const isOwner = response.current?.role === "owner";

  const handleKickModalClose = (shouldRefresh: boolean) => {
    setKickModalVisible(false);
    if(shouldRefresh) refresh();
  };

  const handleLeaveModalClose = (shouldRefresh: boolean) => {
    setLeaveModalVisible(false);
    if(shouldRefresh) refresh();
  };

  const handleDeleteModalClose = (shouldRefresh: boolean) => {
    setDeleteModalVisible(false);
    if(shouldRefresh) refresh();
  };

  return (
    <div className="board">
      <div className="section">
        <h3>{t("board.members.title")}</h3>
        <div className="members">
          {response.members.map(member =>
            <div className="item" key={member.id}>
              <div className="name">{member.name}</div>
              {member.role === "owner" && <FontAwesomeIcon icon="crown" />}
              {isOwner && member.role === "member" && 
                <Button 
                  onClick={() => {
                    setKickModalValues({
                      id: member.id,
                      name: member.name
                    });
                    setKickModalVisible(true);
                  }}
                  icon={<FontAwesomeIcon icon="arrow-right-from-bracket" />}
                  size="small" 
                  mode="link" 
                  color="danger">{t("board.members.remove")}</Button>}
            </div>
          )}
        </div>
        {isOwner && 
          <div className="invite">
            <Button 
              mode="secondary"
              color="primary"
              icon={<FontAwesomeIcon icon="arrow-up-right-from-square" />}
              onClick={() => setInviteModalVisible(true)}>
                {t("board.members.generateInvite.button")}
            </Button>
          </div>}
      </div>
      <ChangeCurrency />
      {isOwner && 
        <div className="section">
          <h3>{t("board.deleteBoard.title")}</h3>
          <Button
            color="danger"
            icon={<FontAwesomeIcon icon="skull" />}
            onClick={() => setDeleteModalVisible(true)}>
              {t("board.deleteBoard.title")}
          </Button>
        </div>}
      {!isOwner && 
        <div className="section">
          <h3>{t("board.leaveBoard.title")}</h3>
          <Button 
            color="danger" 
            icon={<FontAwesomeIcon icon="arrow-right" />}
            onClick={() => setLeaveModalVisible(true)}>
              {t("board.leaveBoard.title")}
          </Button>
        </div>}
      <InviteModal visible={inviteModalVisible} onClose={() => setInviteModalVisible(false)} />
      <KickModal values={kickModalValues} visible={kickModalVisible} onClose={handleKickModalClose} />
      <LeaveModal visible={leaveModalVisible} onClose={handleLeaveModalClose} />
      <DeleteModal visible={deleteModalVisible} onClose={handleDeleteModalClose} />
    </div>
  );
}
