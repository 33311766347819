import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal as BaseModal } from "../../../Components/Modal/Modal";
import { Button, Input, LoaderIcon, Select, Spinner } from "../../../Components/UI";
import { Category, Errors, FormError, useDeleteCategoryApi, useEditCategoryApi, useNewCategoryApi } from "../../../Utilities/Api";
import { useEffect, useState } from "react";
import { Options as IconOptions } from "../../../Utilities/Options/Icons";
import { Options as ColorOptions } from "../../../Utilities/Options/Colors";
import { Confirm } from "../../../Components/Confirm/Confirm";
import { useGlobalError } from "../../../Components/GlobalError/GlobalError";
import { useTranslation } from "react-i18next";

type Props = {
  visible: boolean,
  values: Category | undefined,
  onClose: (refresh: boolean) => void
}

type Mode = "new" | "edit"

export type Form = {
  id: string,
  name: string
  icon: string,
  color: string
};

const defaultValues = {
  id: "",
  name: "",
  icon: "phone",
  color: "0"
};

export default function Modal({ visible, onClose, values }: Props)
{
  const { t } = useTranslation("categories");
  const { setError } = useGlobalError();
  const {send: sendNew, loading: loadingNew} = useNewCategoryApi();
  const {send: sendEdit, loading: loadingEdit} = useEditCategoryApi();
  const {send: sendDelete, loading: loadingDelete} = useDeleteCategoryApi();
  const [deleting, setDeleting] = useState(false);
  const [form, setForm] = useState<Form>(defaultValues);
  const [mode, setMode] = useState<Mode>("new");
  const [errors, setErrors] = useState<Errors>([]);

  const updateForm = (newValues: Partial<Form>) => {
    setForm(v => ({ ...v, ...newValues }));
  };

  useEffect(() => {
    if(!visible) return;
    const newValues = values || defaultValues;
    updateForm(newValues);
    setMode(values === undefined ? "new" : "edit");
    setDeleting(false);
    setErrors([]);
  }, [visible, values]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if(mode === "new")
      sendNew({ name: form.name, icon: form.icon, color: form.color })
        .then(() => onClose(true))
        .catch(reason => {
          if(reason.errors)
            setErrors(reason.errors);
        });
    else
      sendEdit({ id: form.id, name: form.name, icon: form.icon, color: form.color })
        .then(() => onClose(true))
        .catch(reason => {
          if(reason.errors)
          {
            if(reason.errors.includes("category_not_found"))
              setError("warning", t("notFound"));
            setErrors(reason.errors);
          }
        });
  };

  const handleDeleteClick = () => {
    setDeleting(true);
  };

  const handleDeleteProceed = () => {
    sendDelete({ id: form.id })
      .then(() => onClose(true))
      .catch(reason => {
        if(reason.errors && reason.errors.includes("category_not_found"))
          setError("warning", t("notFound"));
      });
  };

  return (
    <BaseModal 
      visible={visible} 
      title={deleting 
        ? t("modal.delete.title")
        : (mode === "new" 
          ? t("modal.new.title")
          : t("modal.edit.title"))} 
      onClose={() => onClose(false)}>
        {deleting 
          ? <Confirm 
            text={t("modal.delete.confirm")}
            proceed={{ text: t("modal.delete.submit"), icon: "skull" }}
            abort={{ text: t("modal.delete.abort") }}
            onProceed={handleDeleteProceed}
            onAbort={() => setDeleting(false)} />
          : <form onSubmit={handleSubmit}>
                <div className="formGroup">
                  <label>{t("modal.form.name.label")}</label>
                  <Input 
                    placeholder={t("modal.form.name.placeholder") || ""}
                    value={form.name} 
                    onChange={e => updateForm({ name: e.target.value })}/>
                  <FormError field="name" errors={errors} />
                </div>
                <div className="formRow">
                  <div className="formGroup half">
                    <label>{t("modal.form.icon.label")}</label>
                    <Select 
                      value={form.icon}
                      onChange={icon => updateForm({ icon })}
                      optionsLayout="grid"
                      options={IconOptions}/>
                  </div>
                  <div className="formGroup half">
                    <label>{t("modal.form.color.label")}</label>
                    <Select 
                      value={form.color} 
                      onChange={color => updateForm({ color })}
                      optionsLayout="grid"
                      options={ColorOptions}/>
                  </div>
                </div>
                {mode === "new" 
                  ? <Button 
                      size="large" 
                      type="submit" 
                      icon={loadingNew && <Spinner><LoaderIcon color='white'/></Spinner>}>
                      {t("modal.new.submit")}
                    </Button>
                  : <div className="formRow">
                      <div className="formGroup half" style={{width: "44px", flex: 0}}>
                        <Button 
                          size="large"
                          mode="secondary"
                          type="button"
                          onClick={handleDeleteClick}
                          icon={loadingDelete
                            ? <Spinner><LoaderIcon color='white'/></Spinner> 
                            : <FontAwesomeIcon icon="trash" />}>
                        </Button>
                      </div>
                      <div className="formGroup half"> 
                        <Button 
                            size="large" 
                            type="submit"
                            icon={loadingEdit && <Spinner><LoaderIcon color='white'/></Spinner>}>
                            {t("modal.edit.submit")}
                          </Button>
                        </div>
                    </div>}
            </form>
        }
    </BaseModal>
  );
}