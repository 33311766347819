import { useApi } from "../Api"

type Props = {
    password: string,
    mail: string
}

type Response = {}

export const useChangeMailApi = () => {
    const { loading, send: sendBase } = useApi();

    const send = (props: Props) => 
        sendBase<Response>("changeMail", "PUT", props);

    return {
        loading,
        send
    };
}