import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Input, Button, LoaderIcon, Spinner } from '../../../../Components/UI';
import { Errors, FormError, useChangeCurrencyApi, useGetCurrencyApi } from '../../../../Utilities/Api';
import { useGlobalSuccess } from '../../../../Components/GlobalSuccess/GlobalSuccess';
import { useTranslation } from 'react-i18next';

export type Form = {
  currency: string
};

const defaultValues = {
    currency: ""
};

export default function ChangeCurrency()
{
  const { t } = useTranslation("settings");
  const { setSuccess } = useGlobalSuccess();
  const { response } = useGetCurrencyApi();
  const {send, loading} = useChangeCurrencyApi();
  const [form, setForm] = useState<Form>(defaultValues);
  const [errors, setErrors] = useState<Errors>([]);

  const updateForm = (newValues: Partial<Form>) => {
    setForm((v: any) => ({ ...v, ...newValues }));
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    send({ 
      currency: form.currency
    })
      .then(() => {
        setErrors([]);
        setSuccess("check", "Currency changed.");
      })
      .catch(reason => {
        if(reason.errors)
          setErrors(reason.errors);
      });
  };

  useEffect(() => {
    updateForm({ currency: response.currency });
  }, [response])

  return (
    <div className="section">
        <h3>{t("board.currency.title")}</h3>
        <form onSubmit={handleSubmit}>
            <div className="formGroup">
                <Input
                    type='currency'
                    placeholder={t("board.currency.placeholder") || ""}
                    value={form.currency}
                    onChange={e => updateForm({ currency: e.target.value })} />
                <FormError field="currency" errors={errors} />
            </div>
            <Button icon={loading ? <Spinner><LoaderIcon color='white'/></Spinner> : <FontAwesomeIcon icon="check" />}>{t("board.currency.submit")}</Button>
        </form>
    </div>
  );
}
