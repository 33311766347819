import React, { ChangeEventHandler, FocusEventHandler, useRef, useState } from 'react';
import './HintInput.scss';
import { Input, Props as InputProps } from '../Input/Input';

type Props = Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "onChange"
  > & {
  onChange: (value: string) => void,
  options: Array<string>
}

export const HintInput = ({ value="", options, placeholder, onChange }: Props) => {
  const [focused, setFocused] = useState(false);
  const focus = focused ? "focus" : "";
  const className = `hintInput ${focus}`;

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = (e: any) => {
    if (!e.currentTarget.parentNode.contains(e.relatedTarget))
      setFocused(false);
  };

  const handleItemBlur = (e: any) => {
    if (!e.currentTarget.parentNode.contains(e.relatedTarget))
      setFocused(false);
  };

  const handleOptionClicked = (value: string) => {
    onChange(value);
    setFocused(false);
  };

  const handleInputChange = (value: string) => {
    onChange(value);
  };

  const visibleOptions = options.filter(
    option => option.toUpperCase().includes(value.toString().toUpperCase())
  );

  const optionsVisible = focused && visibleOptions.length > 0;

  return (
    <div className={className}>
      <Input
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value}
        placeholder={placeholder} 
        onChange={e => handleInputChange(e.target.value) }/>
      {optionsVisible && <div className="options" >
        {visibleOptions.map((option, index) => {
          const active = option === value;
          return (
            <button
              type="button"
              tabIndex={0}
              onBlur={handleItemBlur}
              className={`option ${active}`}
              key={index} 
              onClick={() => handleOptionClicked(option)}>
              {option}
            </button>
          )
        })}
      </div>}
    </div>
  );
}
