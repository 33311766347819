import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetBoardsApi, useNewBoardApi } from '../../Utilities/Api';
import './Boards.scss';
import { Button } from '../../Components/UI';
import { useAuth } from '../../Utilities/Auth/UseAuth';
import { abbreviateText } from '../../Utilities/Format';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Modal from './Modal/Modal';
import { useTranslation } from 'react-i18next';

export default function Boards()
{
  const { t } = useTranslation("boards");
  const navigate = useNavigate();
  const { response, refresh } = useGetBoardsApi();
  const { send } = useNewBoardApi();
  const { setBoard } = useAuth();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const handleNewClick = () => {
    setModalVisible(true);
  };

  const handleClose = (shouldRefresh: boolean) => {
    setModalVisible(false);
    if(shouldRefresh) refresh();
  };

  const handleItemClick = (id: string, name: string) => {
    setBoard(id, name);
    navigate("/summary");
  };

  return (
    <div className="content">
      <header>
        <h2>{t("title")}</h2>
        <Button 
          size="small"
          icon={<FontAwesomeIcon icon="plus"/>} 
          onClick={handleNewClick} />
      </header>
      <div className="boards">
        <div className="container">
          {response.items.map((item, index) => 
              <div 
                className='item'
                key={index} 
                onClick={() => handleItemClick(item.id, item.name)}>
                <div className="icon">{abbreviateText(item.name, 2).toUpperCase()}</div>
                <h2>{item.name}</h2>
              </div>
          )}
        </div>  
      </div>
      <Modal visible={modalVisible} onClose={handleClose} />
    </div>
  );
}
