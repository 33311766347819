import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Member, OneTimePayment } from "../../../Utilities/Api";
import CategoryChip from "../../../Components/CategoryChip/CategoryChip";
import { useContext } from "react";
import { CurrencyContext } from "../../../Utilities/Context/CurrencyContext";

type Props = {
  onClick: () => void,
  members: Array<Member>
} & OneTimePayment


export default function Item({ onClick, type, note, amount, category, user, members }: Props)
{
  const currency = useContext(CurrencyContext);

  let userName;
  if(members.length > 1)
  {
    const member = members.find(({ id }) => id === user);
    if(member) userName = member.name;
  }
  
  return (
    <div className="item" onClick={onClick}>
      <div className={"amount " + (type === "expense" ? "typeExpense" : "typeIncome")}>
        {type === "expense"
          ? <div className="type">
            <FontAwesomeIcon icon="arrow-right" />
            <div className="text">Expense</div>
          </div>
          : <div className="type">
            <FontAwesomeIcon icon="arrow-left" />
            <div className="text">Income</div>
          </div>
        }
        <div className="number">
          {amount}
        </div>
        <div className="unit">
          {currency}
        </div>
      </div>
      <div className="detail">
        <div className="note">
          {note || (type === "expense" ? "Unnamed expense" : "Unnamed income")}
        </div>
        <div className="info">
          {category && <CategoryChip 
            name={category.name} 
            icon={category.icon} 
            color={category.color}
            style="text" />}
          {userName &&
            <div className="user">
              <div className="icon">
                <FontAwesomeIcon icon={"user"} />
              </div>
              <div className="name">{userName}</div>
            </div>}
        </div>
      </div>
    </div>
  );
}