import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import './Switch.scss';

type Props = {
  value: string,
  options: Array<{ value: string, content: React.ReactNode }>
  onChange: (value: string) => void
}

export const Switch = ({ value, options, onChange }: Props) => {
  const handleOptionClicked = (value: string) => {
    onChange(value);
  };

  return (
    <div className="switch">
      <div className="options">
        {options.map((option, index) => {
          const active = option.value === value ? "active" : "";
          return (
            <button 
              type="button"
              className={`option ${active}`}
              key={index} 
              tabIndex={0}
              onClick={() => handleOptionClicked(option.value)}>
              {option.content}
            </button>
          )
        })}
      </div>
    </div>
  );
}