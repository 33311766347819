import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal as BaseModal } from "../../../Components/Modal/Modal";
import { Button, Input, LoaderIcon, PriceInput, PriceInputValue, Select, Spinner } from "../../../Components/UI";
import { Errors, FormError, useDeleteCategoryApi, useDeletePaymentApi, useEditCategoryApi, useEditPaymentApi, useGetCategoriesApi, useNewPaymentApi } from "../../../Utilities/Api";
import { useContext, useEffect, useState } from "react";
import { Options as IconOptions } from "../../../Utilities/Options/Icons";
import { Options as ColorOptions, Colors } from "../../../Utilities/Options/Colors";
import CategoryChip from "../../../Components/CategoryChip/CategoryChip";
import { Confirm } from "../../../Components/Confirm/Confirm";
import { ToText as IntervalsToText } from "../../../Utilities/Options/Intervals";
import { CurrencyContext } from "../../../Utilities/Context/CurrencyContext";
import { useGlobalError } from "../../../Components/GlobalError/GlobalError";
import { useTranslation } from "react-i18next";

type Props = {
  visible: boolean,
  values: Form | undefined,
  onClose: (refresh: boolean) => void
}

type Mode = "new" | "edit"

export type Form = {
  id: string,
  name: string
  icon: string,
  color: string,
  amount: PriceInputValue,
  category: string
};

const defaultValues = {
  id: "",
  name: "",
  icon: "phone",
  color: "0",
  amount: {
    text: "0",
    number: 0
  },
  category: ""
};

export const Modal = ({ visible, onClose, values }: Props) =>
{
  const { t } = useTranslation("income");
  const currency = useContext(CurrencyContext);
  const { setError } = useGlobalError();
  const { response: categories } = useGetCategoriesApi();
  const {send: sendNew, loading: loadingNew} = useNewPaymentApi();
  const {send: sendEdit, loading: loadingEdit} = useEditPaymentApi();
  const {send: sendDelete, loading: loadingDelete} = useDeletePaymentApi();
  const [deleting, setDeleting] = useState(false);
  const [form, setForm] = useState<Form>(defaultValues);
  const [mode, setMode] = useState<Mode>("new");
  const [errors, setErrors] = useState<Errors>([]);

  const updateForm = (newValues: Partial<Form>) => {
    setForm(v => ({ ...v, ...newValues }));
  };

  useEffect(() => {
    if(!visible) return;
    const newValues = values || defaultValues;
    updateForm(newValues);
    setMode(values === undefined ? "new" : "edit");
    setDeleting(false);
    setErrors([]);
  }, [visible, values]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if(mode === "new")
      sendNew({
        type: "income",
        recurring: "recurring",
        id: "",
        amount: form.amount.number,
        name: form.name,
        icon: form.icon,
        color: form.color,
        category: form.category === "" ? undefined : form.category
      })
        .then(() => onClose(true))
        .catch(reason => {
          if(reason.errors)
            setErrors(reason.errors);
        });
    else
      sendEdit({
        type: "income",
        recurring: "recurring",
        id: form.id,
        amount: form.amount.number,
        name: form.name,
        icon: form.icon,
        color: form.color,
        category: form.category === "" ? undefined : form.category
      })
        .then(() => onClose(true))
        .catch(reason => {
          if(reason.errors)
          {
            if(reason.errors.includes("payment_not_found"))
              setError("warning", t("notFound"));
            setErrors(reason.errors);
          }
        });
  };

  const handleDeleteClick = () => {
    setDeleting(true);
  };

  const handleDeleteProceed = () => {
    sendDelete({ id: form.id })
      .then(() => onClose(true))
      .catch(reason => {
        if(reason.errors && reason.errors.includes("payment_not_found"))
          setError("warning", t("notFound"));
      });
  };

  return (
    <BaseModal 
      visible={visible} 
      title={deleting 
        ? t("modal.delete.title")
        : (mode === "new" 
          ? t("modal.new.title")
          : t("modal.edit.title"))} 
      onClose={() => onClose(false)}>
        {deleting 
          ? <Confirm 
            text={t("modal.delete.confirm")}
            proceed={{ text: t("modal.delete.submit"), icon: "skull" }}
            abort={{ text: t("modal.delete.abort") }}
            onProceed={handleDeleteProceed}
            onAbort={() => setDeleting(false)} />
          : <form onSubmit={handleSubmit}>
              <div className="formGroup">
                <label>{t("modal.form.name.label")}</label>
                <Input 
                  placeholder={t("modal.form.name.placeholder") || ""}
                  value={form.name} 
                  onChange={e => updateForm({ name: e.target.value })}/>
                <FormError field="name" errors={errors} />
              </div>
              <div className="formGroup">
                <label>{t("modal.form.amount.label")}</label>
                <PriceInput 
                  value={form.amount}
                  units={currency}
                  onChange={value => updateForm({ amount: value })}/>
                <FormError field="amount" errors={errors} />
              </div>
              <div className="formGroup half">
                <label>{t("modal.form.category.label")}</label>
                <Select 
                  value={form.category}
                  onChange={category => updateForm({ category })}
                  options={[ 
                    {
                      value: "", 
                      content: <div>Uncategorized</div>
                    }, 
                    ...categories.items.map(
                    ({ id, name, color, icon }) => {
                      return {
                        value: id, 
                        content: <div>
                          <CategoryChip name={name} icon={icon} color={color} />
                        </div>
                      };
                    }
                  )]}/>
                <FormError field="category" errors={errors} />
              </div>
              <div className="formRow">
                <div className="formGroup half">
                  <label>{t("modal.form.icon.label")}</label>
                  <Select 
                    value={form.icon}
                    onChange={icon => updateForm({ icon })}
                    optionsLayout="grid"
                    options={IconOptions}/>
                </div>
                <div className="formGroup half">
                  <label>{t("modal.form.color.label")}</label>
                  <Select 
                    value={form.color} 
                    onChange={color => updateForm({ color })}
                    optionsLayout="grid"
                    options={ColorOptions}/>
                </div>
              </div>
              {mode === "new" 
                ? <Button 
                    size="large" 
                    type="submit" 
                    icon={loadingNew && <Spinner><LoaderIcon color='white'/></Spinner>}>
                    {t("modal.new.submit")}
                  </Button>
                : <div className="formRow">
                    <div className="formGroup half" style={{width: "44px", flex: 0}}>
                      <Button
                        size="large"
                        mode="secondary"
                        type="button"
                        onClick={handleDeleteClick}
                        icon={loadingDelete
                          ? <Spinner><LoaderIcon color='white'/></Spinner> 
                          : <FontAwesomeIcon icon="trash" />}>
                      </Button>
                    </div>
                    <div className="formGroup half"> 
                      <Button 
                          size="large" 
                          type="submit"
                          icon={loadingEdit && <Spinner><LoaderIcon color='white'/></Spinner>}>
                          {t("modal.edit.submit")}
                        </Button>
                      </div>
                  </div>}
            </form>
        }
    </BaseModal>
  );
}