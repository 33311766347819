import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, LoaderIcon, Spinner } from "../../Components/UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Errors, FormError, useRegistrationApi } from "../../Utilities/Api";
import { Modal } from "../../Components/Modal/Modal";
import { useTranslation } from "react-i18next";

type Props = {
  visible: boolean,
  onClose: () => void
}

export default function Registration({ visible, onClose }: Props)
{
  const { t } = useTranslation("login");
  const { send, loading } = useRegistrationApi();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<Errors>([]);

  const [mail, setMail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");

  useEffect(() => {
    if(!visible) return;
    setErrors([]);
  }, [visible]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if(password !== passwordRepeat)
    {
      setErrors([{ field: "repeatPassword", message: "registration-repeatPassword-mismatch" }]);
      return;
    }

    send({ mail, name, password })
      .then(() => navigate("/summary"))
      .catch(reason => {
        if(reason.errors)
          setErrors(reason.errors);
      });
  };

  return (
    <Modal 
      visible={visible} 
      title={t("newAccount.title")} 
      onClose={() => onClose()}>
        <form onSubmit={handleSubmit}>
          <div className="formGroup">
            <label>{t("newAccount.mail")}</label>
            <Input type="text" placeholder={t("newAccount.mail") || ""} value={mail} onChange={e => setMail(e.target.value)}/>
            <FormError field="mail" errors={errors} />
          </div>
          <div className="formGroup">
            <label>{t("newAccount.username")}</label>
            <Input type="text" placeholder={t("newAccount.username") || ""} value={name} onChange={e => setName(e.target.value)}/>
            <FormError field="name" errors={errors} />
          </div>
          <div className="formGroup">
            <label>{t("newAccount.password")}</label>
            <Input type="password" placeholder={t("newAccount.password") || ""} value={password} onChange={e => setPassword(e.target.value)}/>
            <FormError field="password" errors={errors} />
          </div>
          <div className="formGroup">
            <label>{t("newAccount.repeatPassword")}</label>
            <Input type="password" placeholder={t("newAccount.repeatPassword") || ""} value={passwordRepeat} onChange={e => setPasswordRepeat(e.target.value)}/>
            <FormError field="repeatPassword" errors={errors} />
          </div>
          <Button size="large" type="submit" icon={loading && <Spinner><LoaderIcon color='white'/></Spinner>}>{t("newAccount.submit")}</Button>
        </form>
    </Modal>
  );
}
