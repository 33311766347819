import './Spinner.scss';

type Props = {
    children: React.ReactNode
}

export const Spinner = ({ children }: Props) => {
    return (
        <div className="spinner">{children}</div>
    )
}