import './Login.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom';
import { Button, Input, LoaderIcon, Spinner } from "../../Components/UI"
import Registration from './Registration';
import { useLoginApi } from '../../Utilities/Api';
import { useState } from 'react';
// import graphic from './login-graphic.svg';
// @ts-ignore
import backgroundImage from './background-image.jpg';
// @ts-ignore
import character from '../../Images/character.svg';
import GlobalError, { useGlobalError } from '../../Components/GlobalError/GlobalError';
import { useTranslation } from 'react-i18next';

type Props = {
  registration?: boolean
}

export default function Login({ registration=false }: Props)
{
  const { t } = useTranslation("login");
  const { setError } = useGlobalError();
  const { send, loading } = useLoginApi();
  const navigate = useNavigate();

  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    send({ mail, password })
      .then(() => navigate("/summary"))
      .catch(reason => {
        setError("user", "User not found!");
      })
  };

  const handleClose = () => {
    navigate("/login");
  };

  return (
    <div className="login">
      <img className="backgroundImage" src={backgroundImage} />
      <div className='section'>
        <form onSubmit={handleSubmit}>
          <header>
            <div className="icon-background"><FontAwesomeIcon icon="user" /></div>
            <span>{t("title")}</span>
          </header>
          <Input 
            type="mail"
            placeholder={t("mail") || ""} 
            value={mail} 
            onChange={e => setMail(e.target.value)}/>
          <Input
            type="password"
            placeholder={t("password") || ""}
            value={password}
            onChange={e => setPassword(e.target.value)}/>
          <Button size="large" type="submit" icon={loading && <Spinner><LoaderIcon color='white'/></Spinner>}>
            {t("submit")}
          </Button>
          <Button size="large" mode="link" type="button" onClick={() => navigate("/new-account")}>
            {t("createNewAccount")}
          </Button>
        </form>
        <div className='logo'>
          <img src={character} />
        </div>
      </div>
      <div className="credits">
        <div className="bold">Monee</div>
        made by krystofpiorecky
      </div>
      <Registration visible={registration} onClose={handleClose} />
      <GlobalError />
    </div>
  );
}
