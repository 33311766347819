
import { useState } from 'react'
import { useAuth } from '../Auth/UseAuth'
import { useGlobalError } from '../../Components/GlobalError/GlobalError'
import { API_ENDPOINT } from '../Constants'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

type SendOpt = {
    minTime?: number
}

type MethodType = "POST" | "GET" | "PUT" | "DELETE"

export const useApi = () => {
    const { t } = useTranslation("errors");
    const navigate = useNavigate();
    const { setBoard } = useAuth();
    const [ loading, setLoading ] = useState(false);
    const { accessToken, setAccessToken } = useAuth();
    const { setError } = useGlobalError();

    const send = <ResposneType>(
        endpoint: string, 
        method: MethodType, 
        params: any, 
        opt?: SendOpt
    ) => new Promise<ResposneType>((resolve, reject) => {
        setLoading(true);

        Promise.all([
            new Promise<void>((resolve) => {
                if(opt?.minTime) setTimeout(resolve, 500)
                else resolve()
            }),
            maybeRefreshRequest<ResposneType>(
                endpoint,
                method,
                params,
                accessToken,
                setAccessToken
            )
        ])
            .then(([_, response]) => response)
            .then(resolve)
            .catch(reason => {
                if(reason.errors) 
                {
                    if(reason.errors.includes("inaccessible_board"))
                    {
                        setError("ban", t("inaccessible_board"));
                        setBoard(reason.board.id, reason.board.name);
                        navigate("/summary");
                    }

                    reject(reason);
                }
                else
                    setError("wifi", t("unreachable_server"));
            })
            .finally(() => {
                setLoading(false);
            });
    });

    return { 
        send,
        loading
    }
}

const maybeRefreshRequest = <ResposneType>(endpoint: string, method: MethodType, params: any, accessToken: string, setAccessToken: any): PromiseLike<ResposneType> => 
    new Promise((resolve, reject) => {
        request(endpoint, method, params, accessToken)
            .then(response => {
                if(response.errors)
                {
                    if(response.errors.includes("invalid_access_token"))
                        refreshRequest(accessToken)
                            .then(refreshResponse => {
                                setAccessToken(refreshResponse.accessToken);
                                request(endpoint, method, params, refreshResponse.accessToken)
                                    .then(response => {
                                        if(response.errors) reject(response);
                                        else resolve(response);
                                    })
                                    .catch(reject);
                            })
                            .catch(reason => {
                                console.log(reason);
                                setAccessToken("");
                                reject(reason);
                            });
                    else 
                        reject(response);
                }
                else resolve(response);
            })
            .catch(reject)
    })
    

const request = (endpoint: string, method: MethodType, params: any, accessToken: string) =>
    fetch(API_ENDPOINT + "/" + endpoint, {
        method: method,
        body: method === "GET"
            ? undefined 
            : JSON.stringify(params),
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken,
            "Access-Control-Allow-Origin": API_ENDPOINT
        },
        credentials: "include"
    })
    .then(response => response.json());
    

const refreshRequest = (accessToken: string) => 
    request(
        "refresh",
        "GET",
        {},
        accessToken
    );