import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import resourcesEN from "./translations/en"
import resourcesCS from "./translations/cs"

export type LocaleNamespace = 
    | "categories"
    | "settings"
    | "login"

i18next.on(
    'languageChanged', 
    lang => {
        document.documentElement.setAttribute('lang', lang);
    }
);

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // lng: "en", // if you're using a language detector, do not define the lng option
        resources: {
            en: resourcesEN,
            cs: resourcesCS
        },
        defaultNS: "common",
        interpolation: {
            escapeValue: false
        }
    });

export default i18next;

export const useTranslation = (ns?: LocaleNamespace | LocaleNamespace[]) => ({
    t: (key: string): string => i18next.t(key, { ns })
});