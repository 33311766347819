import { Modal as BaseModal } from "../../../../Components/Modal/Modal";
import { useRemoveMemberApi } from "../../../../Utilities/Api";
import { Confirm } from "../../../../Components/Confirm/Confirm";
import { useTranslation } from "react-i18next";

type Props = {
  values: {
    id: string,
    name: string
  },
  visible: boolean
  onClose: (refresh: boolean) => void
}

export default function KickModal({ values, visible, onClose }: Props)
{
  const { t } = useTranslation("settings");
  const { send } = useRemoveMemberApi();

  const handleDeleteProceed = () => {
    onClose(true);
    send({ id: values.id })
      .then(() => onClose(true))
      .catch(reason => {});
  };

  return (
    <BaseModal 
      visible={visible} 
      title={t("board.modal.removeMember.title")}
      onClose={() => onClose(false)}>
        <Confirm 
          text={t("board.modal.removeMember.confirm", { name: values.name})}
          proceed={{ text: t("board.modal.removeMember.submit"), icon: "arrow-right-from-bracket" }}
          abort={{ text: t("board.modal.removeMember.abort") }}
          onProceed={handleDeleteProceed}
          onAbort={() => onClose(false)} />
    </BaseModal>
  );
}