import { useAuth } from "../../Auth/UseAuth"
import { useApi } from "../Api"

type Props = {
    userBorrowed: string,
    userLended: string,
    amount: number
}

type Response = {}

export const useAddDebtApi = () => {
    const { loading, send: sendBase } = useApi();
    const { boardId } = useAuth();

    const send = (props: Props) => 
        sendBase<Response>("debt/" + boardId, "POST", props);

    return {
        loading,
        send
    };
}