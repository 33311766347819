import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Debt } from "../../../Utilities/Api/Types/Debt";
import { ArrowVerticalIcon } from "../../../Components/UI";
import { CurrencyContext } from "../../../Utilities/Context/CurrencyContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

type Props = Debt & {
  onClick: () => void,
  userBorrowedAccount: boolean,
  userLendedAccount: boolean
}

export default function Item({ userBorrowed, userLended, amount, userBorrowedAccount, userLendedAccount, onClick }: Props)
{
  const { t } = useTranslation("debt");
  const currency = useContext(CurrencyContext);

  return (
    <div className="item" onClick={onClick}>
      <ArrowVerticalIcon color="#e6e6e6" size={32} />
      <div className="users">
        <UserChip name={userBorrowed} account={userBorrowedAccount} />
        <FontAwesomeIcon icon="arrow-right" />
        <UserChip name={userLended} account={userLendedAccount} />
      </div>
      <div className="detail">
        <div className="value">
          <div className="value">{amount}</div>
          <div className="unit">{currency}</div>
        </div>
        <div className="moreIcon">
          <div className="label">{t("resolve")}</div>
          <FontAwesomeIcon icon="arrow-right" />
        </div>
      </div>
    </div>
  );
}

const UserChip = ({ name, account }: {name: string, account: boolean}) =>
{
  return (
    <div className={account ? "userChip userChipAccount" : "userChip"}>
      <FontAwesomeIcon icon={account ? "user-tie" : ["far", "user"]} />
      <div>{name}</div>
    </div>
  );
}