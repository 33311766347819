import { useAuth } from "../../Auth/UseAuth"
import { useApi } from "../Api"
import { PaymentInput } from "../Types/Payment";

type Response = {
    code: string
};

export const useGenerateBoardInviteApi = () => {
    const { loading, send: sendBase } = useApi();
    const { boardId } = useAuth();

    const send = () => 
        sendBase<Response>("generateBoardInvite/" + boardId, "GET", {});

    return {
        loading,
        send
    };
}