import { Modal as BaseModal } from "../../../Components/Modal/Modal";
import { useDeleteDebtApi} from "../../../Utilities/Api";
import { Confirm } from "../../../Components/Confirm/Confirm";
import { useTranslation } from "react-i18next";

type Props = {
  visible: boolean,
  values: {
    userBorrowed: string,
    userLended: string,
  },
  onClose: (refresh: boolean) => void
}

export const ResolveModal = ({ visible, values, onClose }: Props) =>
{
  const { t } = useTranslation("debt");
  const {send: sendDelete, loading: loadingDelete} = useDeleteDebtApi();

  const handleProceed = () => {
    sendDelete({ 
      userBorrowed: values.userBorrowed,
      userLended: values.userLended
    })
      .then(() => onClose(true))
      .catch(reason => {});
  };

  return (
    <BaseModal 
      visible={visible} 
      title={t("modal.delete.title")} 
      onClose={() => onClose(false)}>
      <Confirm 
        text={t("modal.delete.confirm", { userBorrowed: values.userBorrowed, userLended: values.userLended })}
        proceed={{ text: t("modal.delete.submit"), icon: "check" }}
        abort={{ text: t("modal.delete.abort") }}
        onProceed={handleProceed}
        onAbort={() => onClose(false)} />
    </BaseModal>
  );
}