import { useAuth } from "../../Auth/UseAuth"
import { useApi } from "../Api"
import { PaymentInput } from "../Types/Payment";

type Props = PaymentInput;

type Response = {}

export const useEditPaymentApi = () => {
    const { loading, send: sendBase } = useApi();
    const { boardId } = useAuth();

    const send = (props: Props) => 
        sendBase<Response>("payments/" + boardId, "PUT", props);

    return {
        loading,
        send
    };
}