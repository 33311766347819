export const Colors: Array<{
  name: string,
  color: string,
  background: string,
}> = [
  {
    name: "0",
    color: "hsl(214deg 58% 52%)",
    background: "hsl(214deg 66% 94%)"
  },
  {
    name: "1",
    color: "hsl(250deg 58% 52%)",
    background: "hsl(250deg 66% 94%)"
  },
  {
    name: "2",
    color: "hsl(286deg 58% 52%)",
    background: "hsl(286deg 66% 94%)"
  },
  {
    name: "3",
    color: "hsl(322deg 58% 52%)",
    background: "hsl(322deg 66% 94%)"
  },
  {
    name: "4",
    color: "hsl(358deg 58% 52%)",
    background: "hsl(358deg 66% 94%)"
  },
  {
    name: "5",
    color: "hsl(34deg 58% 52%)",
    background: "hsl(34deg 66% 94%)"
  },
  {
    name: "6",
    color: "hsl(70deg 58% 52%)",
    background: "hsl(70deg 66% 94%)"
  },
  {
    name: "7",
    color: "hsl(106deg 58% 52%)",
    background: "hsl(106deg 66% 94%)"
  },
  {
    name: "8",
    color: "hsl(142deg 58% 52%)",
    background: "hsl(142deg 66% 94%)"
  },
  {
    name: "9",
    color: "hsl(178deg 58% 52%)",
    background: "hsl(178deg 66% 94%)"
  }
];

export const Options = Colors.map(({ color, name }) => ({
  value: name,
  content: <div>
    <div style={{height: "16px", width: "16px", borderRadius: "100%", backgroundColor: color, }}></div>
  </div>
}))