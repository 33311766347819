import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './GlobalSuccess.scss';
import { create } from "zustand";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export default function GlobalSuccess()
{
  const { icon, message, visible, hideSuccess } = useGlobalSuccess();

  return (
    <div className={"globalSuccess" + (visible ? " visible" : "")}>
        <div className="icon">
            <FontAwesomeIcon icon={icon} />
        </div>
        <div className="message">
          {message}
        </div>
        <div className="close" onClick={hideSuccess}>
          <FontAwesomeIcon icon="times" />
        </div>
    </div>
  );
};

export type GlobalSuccessType = {
  icon: IconProp,
  message: string,
  visible: boolean,
  setSuccess: (icon: IconProp, message: string) => void,
  hideSuccess: () => void
}

let hideTimeout: NodeJS.Timeout;

export const useGlobalSuccess = create<GlobalSuccessType>()(
  (set, get) => ({
      icon: "check",
      message: "",
      visible: false,
      setSuccess: (icon: IconProp, message: string) => {
        clearTimeout(hideTimeout);
        hideTimeout = setTimeout(() => set(() => ({ visible: false })), 5000);
        set(() => ({ icon, message, visible: true }));
      },
      hideSuccess: () => { set(() => ({ visible: false })); }
  })
);