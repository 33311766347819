import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../UI";
import './Modal.scss';
import { useEffect, useState } from "react";

type Props = {
  visible: boolean,
  title: string,
  children: React.ReactNode,
  onClose: () => void
}

export const Modal = ({ visible, title, children, onClose }: Props) =>
{
  const [inDOM, setInDOM] = useState<boolean>(visible);
  const [inStyles, setInStyles] = useState<boolean>(visible);

  useEffect(() => {
    if(visible)
    {
      setInDOM(true);
      setTimeout(() => setInStyles(true), 10);
    }
    else
    {
      setInStyles(false);
      setTimeout(() => setInDOM(visible), 200);
    }
  }, [visible])

  const hiddenFlag = inStyles ? "" : "hidden";
  const className = `modal ${hiddenFlag}`;

  return inDOM
    ? (
    <div className={className}>
      <div className="content">
        <header>
          <h2>{title}</h2>
          <div className="close">
              <Button size="large" mode="link" type="button" onClick={onClose} icon={<FontAwesomeIcon icon="times" />}></Button>
          </div>
        </header>
        <div className="content">
          {children}
        </div>
      </div>
    </div>
  )
  : <></>;
}