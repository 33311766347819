import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Icons: Array<IconProp> = [
  "phone",
  "utensils",
  "home",
  "shirt",
  "car-side",
  "train-tram",
  "wifi",
  "music",
  "clapperboard",
  "computer",
  "champagne-glasses",
  "sack-dollar",
  "pills",
  "heart",
  "dumbbell"
];

export const Options = Icons.map(name => ({
  value: name as string,
  content: <div><FontAwesomeIcon icon={name} /></div>
}))