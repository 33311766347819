import { useEffect, useState } from "react"
import { useApi } from "../Api"
import { useAuth } from "../../Auth/UseAuth"
import { OneTimePayment } from "../Types/Payment"
import { Member } from "../Types/Member"

type Response = {
    currency: string,
    members: Array<Member>,
    items: Array<OneTimePayment>
}

export const useGetOneTimePaymentsApi = () => {
    const { loading, send: sendBase } = useApi();
    const [ response, setResponse ] = useState<Response>({ currency: "", items: [], members: [] });
    const [ error, setError ] = useState({ });
    const { boardId } = useAuth();

    const refresh = () =>
        sendBase<Response>("payments/oneTime/all/" + boardId, "GET", {})
            .then(setResponse)
            .catch(setError);

    useEffect(() => {
        refresh();
    }, [])

    return {
        loading,
        response,
        error, 
        refresh
    }
}