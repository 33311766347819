import './NavItem.scss';

type Props = {
  onClick: () => void,
  text: string,
  icon: React.ReactNode
}

export default function NavItem({ onClick, icon, text }: Props)
{
  return (
    <div className="nav-item" onClick={onClick}>
        <div className="icon-background">
            {icon}
        </div>
        <div className="text">{text}</div>
    </div>
  );
}
