import { SummaryGroupTable } from "../../../Utilities/Api";
import "./DisplayTable.scss";

type Props = SummaryGroupTable

export default function DisplayReslover({ data, meta }: Props)
{
  return <div className="displayTable">
    <div className="row header">
      {meta.columns.map((column, i) => (
        <div className="column">{column.label}</div>
      ))}
    </div>
    {data.groups.map((group, i) => (
      <div className="row group">
        <div className="column label">{group.label}</div>
        <div className="column value">{group.values.join(", ")}</div>
      </div>
    ))}
  </div>
}