import categories from "./categories.json"
import settings from "./settings.json"
import login from "./login.json"
import summary from "./summary.json"
import nav from "./nav.json"
import boards from "./boards.json"
import debt from "./debt.json"
import income from "./income.json"
import subscriptions from "./subscriptions.json"
import payments from "./payments.json"
import errors from "./errors.json"

export default { categories, settings, login, summary, nav, boards, debt, income, subscriptions, payments, errors }