import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './GlobalError.scss';
import { create } from "zustand";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export default function GlobalError()
{
  const { icon, message, visible, hideError } = useGlobalError();

  return (
    <div className={"globalError" + (visible ? " visible" : "")}>
        <div className="icon">
            <FontAwesomeIcon icon={icon} />
        </div>
        <div className="message">
          {message}
        </div>
        <div className="close" onClick={hideError}>
          <FontAwesomeIcon icon="times" />
        </div>
    </div>
  );
};

export type GlobalErrorType = {
  icon: IconProp,
  message: string,
  visible: boolean,
  setError: (icon: IconProp, message: string) => void,
  hideError: () => void
}

let hideTimeout: NodeJS.Timeout;

export const useGlobalError = create<GlobalErrorType>()(
  (set, get) => ({
      icon: "wifi",
      message: "",
      visible: false,
      setError: (icon: IconProp, message: string) => {
        clearTimeout(hideTimeout);
        hideTimeout = setTimeout(() => set(() => ({ visible: false })), 5000);
        set(() => ({ icon, message, visible: true }));
      },
      hideError: () => { set(() => ({ visible: false })); }
  })
);