import { useAuth } from "../../Auth/UseAuth"
import { useApi } from "../Api"

type Props = {
    mail: string,
    name: string,
    password: string
}

type Response = {
    accessToken: string,
    board: {
        id: string,
        name: string
    }
}

export const useRegistrationApi = () => {
    const { setAccessToken, setBoard } = useAuth();
    const { loading, send: sendBase } = useApi();

    const send = (props: Props) => new Promise<Response>((resolve, reject) => {
        sendBase<Response>("registration", "POST", props, { minTime: 500 })
            .then(response => {
                setAccessToken(response.accessToken);
                setBoard(response.board.id, response.board.name);
                resolve(response);
            })
            .catch(reject);
    });

    return {
        loading,
        send
    };
}