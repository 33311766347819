import { useTranslation } from "react-i18next";

export default function About()
{
  const { t } = useTranslation("settings");

  return (
    <div className="user">
      <div className="section">
        <h3>{t("about.title")}</h3>
        <p>{t("about.description")}</p>
      </div>
    </div>
  );
}
