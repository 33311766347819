import { useState } from 'react';
import { Category, useGetCategoriesApi } from '../../Utilities/Api';
import Item from './Item/Item'
import './Categories.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, EmptyList } from '../../Components/UI';
import Modal from './Modal/Modal';
import { useTranslation } from '../../Utilities/Locale/i18n';

export default function Categories()
{
  const { t } = useTranslation("categories");
  const { response, refresh } = useGetCategoriesApi();
  const [modal, setModal] = useState<Category>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const handleNewClick = () => {
    setModalVisible(true);
    setModal(undefined);
  };

  const handleClose = (shouldRefresh: boolean) => {
    setModalVisible(false);
    if(shouldRefresh) refresh();
  };

  const handleItemClick = (item: Category) => {
    setModalVisible(true);
    setModal(item);
  };

  return (
    <div className="content">
      <header>
        <h2>{t('title')}</h2>
        <Button 
          size="small"
          icon={<FontAwesomeIcon icon="plus"/>} 
          onClick={handleNewClick} />
      </header>
      <div className="categories">
        <div className="container">
          {response.items.length === 0
            ? <EmptyList text={t('empty.text')} action={{ text: t('empty.action'), callback: handleNewClick}}/>
            : response.items.map((item, index) => 
                <Item 
                  key={index}
                  onClick={() => handleItemClick(item)}
                  {...item} />
              )}
        </div>
      </div>
      <Modal visible={modalVisible} onClose={handleClose} values={modal} />
    </div>
  );
}
