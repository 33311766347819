import { useState } from "react"
import { useApi } from "../Api"
import { useAuth } from "../../Auth/UseAuth"
import { Board } from "../Types/Board"

type Props = {
    code: string
};

type Response = {
    board?: Board
};

export const useAcceptBoardInviteApi = () => {
    const { loading, send: sendBase } = useApi();
    const [ response, setResponse ] = useState<Response>({ });
    const [ error, setError ] = useState({ });

    const send = (props: Props) =>
        sendBase<Response>("acceptBoardInvite", "POST", props)
            .then(setResponse)
            .catch(setError);

    return {
        loading,
        response,
        error,
        send
    };
}