import { useEffect, useState } from 'react';
import './PriceInput.scss';
import { Input } from '../Input/Input';
import { Button } from '../Button/Button';
import { parseNumber } from '../../../Utilities/Number';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  value: PriceInputValue,
  units: ((number: number) => string) | string,
  onChange: (value: PriceInputValue) => void
}

export type PriceInputValue = {
  text: string,
  number: number
}

const buttons = [
  { key: "1", content: "1" },
  { key: "2", content: "2" },
  { key: "3", content: "3" },
  { key: "/", content: <FontAwesomeIcon icon="divide" /> },
  { key: "4", content: "4" },
  { key: "5", content: "5" },
  { key: "6", content: "6" },
  { key: "*", content: <FontAwesomeIcon icon="times" /> },
  { key: "7", content: "7" },
  { key: "8", content: "8" },
  { key: "9", content: "9" },
  { key: "-", content: <FontAwesomeIcon icon="minus" /> },
  { key: ".", content: "." },
  { key: "0", content: "0" },
  { key: "BS", content: <FontAwesomeIcon icon="delete-left" /> },
  { key: "+", content: <FontAwesomeIcon icon="plus" /> }
]

export const PriceInput = ({ value={ text: "0", number: 0 }, units, onChange }: Props) => {
  const [focused, setFocused] = useState(false);
  const focus = focused ? "focus" : "";
  const className = `priceInput ${focus}`;

  const handleKeydown = (e: KeyboardEvent) => {
    if(!focused) return;
    
    const k = e.key;
    if(k === "Backspace") 
      addToText("BS");
    else if (buttons.find(({key}) => key === k)) 
      addToText(k);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeydown);
    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, [value, handleKeydown]);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = (e: any) => {
    if (!e.currentTarget.contains(e.relatedTarget))
      setFocused(false);
  };

  const addToText = (v: string) => {
    const text = value.text;
    const textLength = text.length

    if(v === "BS")
    {
      if(textLength === 1)
        setText("0");
      else if(textLength > 0)
        setText(text.substring(0, textLength - 1));
    }
    else if(v === ".")
    {
      if(textLength > 0 && "+-*/".includes(text[textLength - 1]))
        setText(text + "0.");
      else if(textLength > 0 && text[textLength - 1] !== ".")
        setText(text + v);
      else if(textLength === 0)
      setText("0.");
    }
    else if("+-*/".includes(v))
    {
      if(textLength > 0)
      {
        if("+-*/.".includes(text[textLength - 1]))
          setText(text.substring(0, textLength - 1) + v);
        else
          setText(text + v);
      }
    }
    else if(text === "0")
      setText(v);
    else 
      setText(text + v);
  };

  const setText = (v: string) => {
    const number = parseNumber(v);
    onChange({
      text: v,
      number: number
    });
  };

  const handleCommitCalculated = () => {
    onChange({
      text: value.number.toString(),
      number: value.number
    });
  };

  const optionsVisible = focused;
  const unit = typeof units === "function" ? units(value.number) : units;

  return (
    <div
      onFocus={handleFocus}
      onBlur={handleBlur}
      tabIndex={0}
      className={className}>
      <div className="field">
        <div className="valueText">
          {value.text}
        </div>
        {(value.number !== Number(value.text) && value.text.length > 0)
          ? <div className="valueNumber" onClick={handleCommitCalculated}>
              {value.number} {unit}
            </div>
          : <div className="currency">
              {unit}
            </div>
        }
      </div>
      {optionsVisible && <div className="options">
        {buttons.map(({ key, content}) => 
          <Button tabIndex={-1} key={key} type="button" onClick={() => addToText(key)}>
            {content}
          </Button> 
        )}
      </div>}
    </div>
  );
}
