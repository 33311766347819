import { useTranslation } from 'react-i18next';
import { useGetSummaryApi } from '../../Utilities/Api';
import { CurrencyContext } from '../../Utilities/Context/CurrencyContext';
import Group from './Group';
import './Summary.scss';
import { EmptyList } from '../../Components/UI';

export default function Summary()
{
  const { t } = useTranslation("summary");
  const { response, refresh } = useGetSummaryApi();

  if(!response) return <></>;

  return (
    <div className="summary">
      <CurrencyContext.Provider value={response.currency}>
        {response.tiles.length === 0
          ? <div className='tile'><EmptyList text="Graphs will show once you add payments."/></div>
          : response.tiles.map((tile, i) => (
            <div className='tile' key={i}>
              {tile.title && <h3>{t(tile.title)}</h3>}
              {tile.subtitle && <div className='subtitle'>{t(tile.subtitle)}</div>}
              {tile.groups.map((group, j) => (
                <Group key={j} {...group} />
              ))}
            </div>))
        }
      </CurrencyContext.Provider>
    </div>
  );
}
