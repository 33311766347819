import { Modal as BaseModal } from "../../../Components/Modal/Modal";
import { Button, HintInput, LoaderIcon, PriceInput, Spinner, PriceInputValue } from "../../../Components/UI";
import { Errors, FormError, useAddDebtApi, useGetDebtUsernamesApi } from "../../../Utilities/Api";
import { useContext, useEffect, useState } from "react";
import { CurrencyContext } from "../../../Utilities/Context/CurrencyContext";
import { useTranslation } from "react-i18next";

type Props = {
  visible: boolean,
  onClose: (refresh: boolean) => void
}

export type Form = {
  userLended: string
  userBorrowed: string,
  amount: PriceInputValue
};

const defaultValues = {
  userLended: "",
  userBorrowed: "",
  amount: {
    text: "0",
    number: 0
  }
};

export const Modal = ({ visible, onClose }: Props) =>
{
  const { t } = useTranslation("debt");
  const currency = useContext(CurrencyContext);
  const { response: names, refresh } = useGetDebtUsernamesApi();
  const {send, loading} = useAddDebtApi();
  const [form, setForm] = useState<Form>(defaultValues);
  const [errors, setErrors] = useState<Errors>([]);

  const updateForm = (newValues: Partial<Form>) => {
    setForm(v => ({ ...v, ...newValues }));
  };

  useEffect(() => {
    if(!visible) return;
    updateForm(defaultValues);
    setErrors([]);
    refresh();
  }, [visible]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    send({
      userBorrowed: form.userBorrowed,
      userLended: form.userLended,
      amount: form.amount.number
    })
      .then(() => onClose(true))
      .catch(reason => {
        if(reason.errors)
          setErrors(reason.errors);
      });
  };

  return (
    <BaseModal 
      visible={visible} 
      title={t("modal.new.title")} 
      onClose={() => onClose(false)}>
      <form onSubmit={handleSubmit}>
        <div className="formGroup">
          <label>{t("modal.form.amount.label")}</label>
          <PriceInput
            value={form.amount}
            units={currency}
            onChange={value => updateForm({ amount: value })}/>
          <FormError field="amount" errors={errors} />
        </div>
        <div className="formGroup">
          <label>{t("modal.form.userBorrowed.label")}</label>
          <HintInput 
            placeholder={t("modal.form.userBorrowed.placeholder") || ""}
            value={form.userBorrowed}
            onChange={value => updateForm({ userBorrowed: value })} 
            options={names.items}/>
          <FormError field="userBorrowed" errors={errors} />
        </div>
        <div className="formGroup">
          <label>{t("modal.form.userLended.label")}</label>
          <HintInput 
            placeholder={t("modal.form.userLended.placeholder") || ""}
            value={form.userLended}
            onChange={value => updateForm({ userLended: value })} 
            options={names.items}/>
          <FormError field="userLended" errors={errors} />
        </div>
        <Button 
          size="large" 
          type="submit" 
          icon={loading && <Spinner><LoaderIcon color='white'/></Spinner>}>
          {t("modal.new.submit")}
        </Button>
      </form>
    </BaseModal>
  );
}