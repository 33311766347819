import './Page.scss';
import Nav from '../Nav/Nav';
import NavBar from '../NavBar/NavBar';
import { useEffect, useState } from 'react';
import { Button } from '../UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../../Pages/Payments/Modal/Modal';
import GlobalError from '../GlobalError/GlobalError';
import { useSettings } from '../../Utilities/Settings/UseSettings';
import GlobalSuccess from '../GlobalSuccess/GlobalSuccess';
import { useTranslation } from 'react-i18next';
import { CurrencyContext } from '../../Utilities/Context/CurrencyContext';
import { useGetCurrencyApi } from '../../Utilities/Api';

type Props = {
  id: string,
  title?: string,
  children: React.ReactNode
}

export default function Page({ id, title, children }: Props)
{
  const { t } = useTranslation("nav");
  const { theme } = useSettings();

  const { response, refresh } = useGetCurrencyApi();
  const [menuOpen, setMenuOpen] = useState(false);
  const [hotActionModalVisible, setHotActionModalVisible] = useState<boolean>(false);

  const handleHotActionClick = () => {
    refresh();
    setHotActionModalVisible(true);
  };

  const handleHotActionClose = (shouldRefresh: boolean) => {
    setHotActionModalVisible(false);
  };

  return (
    <div className={`page theme-${theme}`}>
      <div>
        <Nav open={menuOpen} onMenuHide={() => setMenuOpen(false)}/>
        {title 
          ? (
            <div className="content">
              <header>
                <h2>{t(title)}</h2>
              </header>
              {children}
            </div>)
          : 
          children}
      </div>
      <NavBar 
        activeItem={id} 
        onMenuToggle={() => setMenuOpen(!menuOpen)}
        onMenuHide={() => setMenuOpen(false)}/>
      <div className="hotAction">
        <Button 
          onClick={handleHotActionClick}
          icon={<FontAwesomeIcon icon={"plus"} />} 
          size="large" />
      </div>
      <CurrencyContext.Provider value={response.currency}>
        <Modal visible={hotActionModalVisible} onClose={handleHotActionClose} values={undefined} />
      </CurrencyContext.Provider>
      <GlobalError />
      <GlobalSuccess />
    </div>
  );
}
