import { useEffect, useState } from "react"
import { useApi } from "../Api"
import { useAuth } from "../../Auth/UseAuth"
import { Debt } from "../Types/Debt"

type Response = {
    currency: string,
    items: Array<Debt>
    members: Array<string>
}

export const useGetDebtApi = () => {
    const { loading, send: sendBase } = useApi();
    const [ response, setResponse ] = useState<Response>({ currency: "", items: [], members: [] });
    const [ error, setError ] = useState({ });
    const { boardId } = useAuth();

    const refresh = () =>
        sendBase<Response>("debt/" + boardId, "GET", { })
            .then(setResponse)
            .catch(setError);

    useEffect(() => {
        refresh();
    }, []);

    return {
        loading,
        response,
        error,
        refresh
    }
}