import { Modal as BaseModal } from "../../../../Components/Modal/Modal";
import { useRemoveMemberApi } from "../../../../Utilities/Api";
import { Confirm } from "../../../../Components/Confirm/Confirm";
import { useTranslation } from "react-i18next";

type Props = {
  visible: boolean
  onClose: (refresh: boolean) => void
}

export default function LeaveModal({ visible, onClose }: Props)
{
  const { t } = useTranslation("settings");
  const { send } = useRemoveMemberApi();

  const handleProceed = () => {
    onClose(true);
    send({ })
      .then(() => onClose(true))
      .catch(reason => {});
  };

  return (
    <BaseModal 
      visible={visible} 
      title={t("board.modal.leave.title")}
      onClose={() => onClose(false)}>
        <Confirm 
          text={t("board.modal.leave.confirm")}
          proceed={{ text: t("board.modal.leave.submit"), icon: "arrow-right" }}
          abort={{ text: t("board.modal.leave.abort") }}
          onProceed={handleProceed}
          onAbort={() => onClose(false)} />
    </BaseModal>
  );
}