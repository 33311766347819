import { UseBoundStore, create } from "zustand"
import { persist } from 'zustand/middleware'
import i18n from "../Locale/i18n"

export type LanguageType = "cs" | "en"

export type ThemeType = "light" | "dark"

export type SettingsType = {
    language: LanguageType,
    theme: ThemeType,
    setLanguage: (language: LanguageType) => void,
    setTheme: (theme: ThemeType) => void
}

export const useSettings = create<SettingsType>()(persist(
    (set, get) => ({
        language: "cs",
        theme: "light",
        setLanguage: (language: LanguageType) => {
            i18n.changeLanguage(language);
            set(() => ({ language }));
        },
        setTheme: (theme: ThemeType) => set(() => ({ theme }))
    }),
    {
        name: "settingsStore"
    }
));