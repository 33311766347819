import ChangeMail from './ChangeMail';
import ChangePassword from './ChangePassword';

export default function User()
{
  return (
    <div className="user">
      <ChangePassword />
      <ChangeMail />
    </div>
  );
}
