type Group<ItemType, ConditionOutput> = { 
    condition: ConditionOutput, 
    items:  Array<ItemType>
};

export const groupBy = <ItemType, ConditionOutput>(
    items: Array<ItemType>, 
    condition: (item: ItemType) => ConditionOutput
): Array<Group<ItemType, ConditionOutput>> => {
    return items.reduce((groups, item) => {
        const conditionValue = condition(item);
        
        let group = groups.find(g => g.condition === conditionValue);
        if(group === undefined)
        {
            group = {
                condition: conditionValue,
                items: []
            };
            groups.push(group);
        }

        group.items.push(item);

        return groups;
    }, [] as Array<Group<ItemType, ConditionOutput>>);
};