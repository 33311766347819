import { useTranslation } from "react-i18next";
import { Modal as BaseModal } from "../../../Components/Modal/Modal";
import { Button, Input, LoaderIcon, Spinner } from "../../../Components/UI";
import { Category, Errors, FormError, useNewBoardApi } from "../../../Utilities/Api";
import { useEffect, useState } from "react";

type Props = {
  visible: boolean,
  onClose: (refresh: boolean) => void
}

export default function Modal({ visible, onClose }: Props)
{
  const { t } = useTranslation("boards");
  const {send, loading} = useNewBoardApi();
  const [name, setName] = useState("");
  const [errors, setErrors] = useState<Errors>([]);

  useEffect(() => {
    setName("");
    setErrors([]);
  }, [visible]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    send({ name })
      .then(() => onClose(true))
      .catch(reason => {
        if(reason.errors)
          setErrors(reason.errors);
      });
  };

  return (
    <BaseModal 
      visible={visible} 
      title={t("modal.title")} 
      onClose={() => onClose(false)}>
        <form onSubmit={handleSubmit}>
            <div className="formGroup">
              <Input placeholder={t("modal.placeholder") || ""} value={name} onChange={e => setName(e.target.value)}/>
              <FormError field="name" errors={errors} />
            </div>
            <Button 
              size="large" 
              type="submit" 
              icon={loading && <Spinner><LoaderIcon color='white'/></Spinner>}>
              {t("modal.submit")}
            </Button>
        </form>
    </BaseModal>
  );
}