import { Cell, Pie, PieChart } from "recharts";
import { SummaryGroupPieChart } from "../../../Utilities/Api";
import "./DisplayPieChart.scss";
import { Colors } from "../../../Utilities/Options/Colors";
import { useSettings } from "../../../Utilities/Settings/UseSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useContext } from "react";
import { CurrencyContext } from "../../../Utilities/Context/CurrencyContext";

type Props = SummaryGroupPieChart

export default function DisplayPieChart({ data }: Props)
{
  const currency = useContext(CurrencyContext);
  const { theme } = useSettings();

  const colors = data.groups.map(group => {
    return Colors.find(c => c.name === group.color)?.color || "#eee";
  });

  const backgrounds = data.groups.map(group => {
    return Colors.find(c => c.name === group.color)?.background || "#eee";
  });

  const groups = data.groups.map((group, index) => ({
    group: group?.icon ? group : undefined,
    value: Math.log(group.values[0] * 2),
    sum: group.values[0]
  }));

  return <div className="displayPieChart">
    <PieChart width={200} height={200}  >
      <Pie 
        tabIndex={-1}
        data={groups}
        cx={"50%"}
        cy={"50%"}
        innerRadius={40}
        outerRadius={80}
        strokeWidth={0}
        paddingAngle={10}
        dataKey="value"
        cornerRadius={13}
        label={renderCustomLabel}
      >
        {groups.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={backgrounds[index % backgrounds.length]} />
        ))}
      </Pie>
    </PieChart>
    <div className="detail">
      {groups.map((entry, index) => (
        <div className="item" key={index}>
          <div className="category" style={{ 
            color: Colors.find(c => c.name === entry.group?.color)?.color || "#aaa"
          }}>
            <div className="icon" style={{ 
              background: Colors.find(c => c.name === entry.group?.color)?.background || "#eee"
            }}>
              <FontAwesomeIcon icon={entry.group?.icon || "question"} />
            </div>
            <div className="name">{entry.group?.label || "Uncategorized"}</div>
          </div>
          <div className="info">
            <div className="sum">
              <div className="value">{entry.sum}</div>
              <div className="unit">{currency}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
}

type CustomLabelType = {
  cx: number,
  cy: number,
  midAngle: number,
  innerRadius: number,
  outerRadius: number,
  percent: number,
  index: number,
  group?: {
    icon: IconProp,
    label: string,
    color: string
  }
};

const RADIAN = Math.PI / 180;
const renderCustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, group, ...props }: CustomLabelType) => {
  const iconSize = 12;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const icon = group ? group.icon : "question";

  const color = group ? Colors.find(c => c.name === group.color)?.color : "#aaa";

  return <FontAwesomeIcon 
    icon={icon}
    color={color} 
    height={iconSize} 
    x={x - 100}
    y={y - iconSize/2} />;
};