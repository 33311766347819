export const FlagENIcon = ({ size=16 }) => {
    return (
        <svg 
            id="Layer_1" 
            data-name="Layer 1" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 16 16"
            style={{ width: size, height: size}}>
            <path style={{fill: "#ed4c5c"}} d="M12.26,1.23A7.92,7.92,0,0,0,8,0V1.23Z"/>
            <path style={{fill: "#fff"}} d="M8,2.46h5.77a8.12,8.12,0,0,0-1.51-1.23H8Z"/>
            <path style={{fill: "#ed4c5c"}} d="M8,3.69h6.74a8.9,8.9,0,0,0-1-1.23H8Z"/>
            <path style={{fill: "#fff"}} d="M8,4.92h7.38a8,8,0,0,0-.64-1.23H8Z"/>
            <path style={{fill: "#ed4c5c"}} d="M8,6.15h7.78a7.08,7.08,0,0,0-.4-1.23H8Z"/>
            <path style={{fill: "#fff"}} d="M8,7.38h8a7.16,7.16,0,0,0-.19-1.23H8Z"/>
            <path style={{fill: "#ed4c5c"}} d="M16,7.38H8V8H0c0,.2,0,.41,0,.61H16c0-.2,0-.41,0-.61S16,7.58,16,7.38Z"/>
            <path style={{fill: "#fff"}} d="M.21,9.83H15.79A8.15,8.15,0,0,0,16,8.61H0A8.15,8.15,0,0,0,.21,9.83Z"/>
            <path style={{fill: "#ed4c5c"}} d="M.61,11.06H15.39a8,8,0,0,0,.4-1.23H.21A7.08,7.08,0,0,0,.61,11.06Z"/>
            <path style={{fill: "#fff"}} d="M1.25,12.29h13.5a8,8,0,0,0,.64-1.23H.61A8,8,0,0,0,1.25,12.29Z"/>
            <path style={{fill: "#ed4c5c"}} d="M2.21,13.52H13.79a7.67,7.67,0,0,0,1-1.23H1.25A8.28,8.28,0,0,0,2.21,13.52Z"/>
            <path style={{fill: "#fff"}} d="M3.71,14.75h8.58a7.68,7.68,0,0,0,1.49-1.23H2.21A8.06,8.06,0,0,0,3.71,14.75Z"/>
            <path style={{fill: "#ed4c5c"}} d="M8,16a8,8,0,0,0,4.29-1.25H3.71A8,8,0,0,0,8,16Z"/>
            <path style={{fill: "#428bc1"}} d="M3.74,1.23A8.12,8.12,0,0,0,2.23,2.46a8.9,8.9,0,0,0-1,1.23A8,8,0,0,0,.62,4.92a7.08,7.08,0,0,0-.4,1.23A7.16,7.16,0,0,0,0,7.38c0,.2,0,.41,0,.62H8V0A7.92,7.92,0,0,0,3.74,1.23Z"/>
            <polygon style={{fill: "#fff"}} points="6.13 0.27 6.26 0.66 6.67 0.66 6.34 0.92 6.46 1.33 6.13 1.09 5.8 1.33 5.93 0.92 5.6 0.66 6.01 0.66 6.13 0.27"/>
            <polygon style={{fill: "#fff"}} points="7.2 1.87 7.33 2.26 7.73 2.26 7.4 2.52 7.53 2.93 7.2 2.69 6.87 2.93 7 2.52 6.67 2.26 7.07 2.26 7.2 1.87"/>
            <polygon style={{fill: "#fff"}} points="5.07 1.87 5.19 2.26 5.6 2.26 5.27 2.52 5.4 2.93 5.07 2.69 4.74 2.93 4.86 2.52 4.53 2.26 4.94 2.26 5.07 1.87"/>
            <polygon style={{fill: "#fff"}} points="6.13 3.47 6.26 3.86 6.67 3.86 6.34 4.12 6.46 4.53 6.13 4.29 5.8 4.53 5.93 4.12 5.6 3.86 6.01 3.86 6.13 3.47"/>
            <polygon style={{fill: "#fff"}} points="4 3.47 4.13 3.86 4.53 3.86 4.2 4.12 4.33 4.53 4 4.29 3.67 4.53 3.8 4.12 3.47 3.86 3.87 3.86 4 3.47"/>
            <polygon style={{fill: "#fff"}} points="1.87 3.47 1.99 3.86 2.4 3.86 2.07 4.12 2.2 4.53 1.87 4.29 1.54 4.53 1.66 4.12 1.33 3.86 1.74 3.86 1.87 3.47"/>
            <polygon style={{fill: "#fff"}} points="7.2 5.07 7.33 5.46 7.73 5.46 7.4 5.72 7.53 6.13 7.2 5.89 6.87 6.13 7 5.72 6.67 5.46 7.07 5.46 7.2 5.07"/>
            <polygon style={{fill: "#fff"}} points="5.07 5.07 5.19 5.46 5.6 5.46 5.27 5.72 5.4 6.13 5.07 5.89 4.74 6.13 4.86 5.72 4.53 5.46 4.94 5.46 5.07 5.07"/>
            <polygon style={{fill: "#fff"}} points="2.93 5.07 3.06 5.46 3.47 5.46 3.14 5.72 3.26 6.13 2.93 5.89 2.6 6.13 2.73 5.72 2.4 5.46 2.81 5.46 2.93 5.07"/>
            <polygon style={{fill: "#fff"}} points="6.13 6.67 6.26 7.06 6.67 7.06 6.34 7.32 6.46 7.73 6.13 7.49 5.8 7.73 5.93 7.32 5.6 7.06 6.01 7.06 6.13 6.67"/>
            <polygon style={{fill: "#fff"}} points="4 6.67 4.13 7.06 4.53 7.06 4.2 7.32 4.33 7.73 4 7.49 3.67 7.73 3.8 7.32 3.47 7.06 3.87 7.06 4 6.67"/>
            <polygon style={{fill: "#fff"}} points="1.87 6.67 1.99 7.06 2.4 7.06 2.07 7.32 2.2 7.73 1.87 7.49 1.54 7.73 1.66 7.32 1.33 7.06 1.74 7.06 1.87 6.67"/>
            <path style={{fill: "#fff"}} d="M2.6,2.93l.33-.24.33.24-.12-.41.33-.26H3.06l-.13-.39-.12.39H2.42l.31.25Z"/>
            <path style={{fill: "#fff"}} d="M.47,6.13.8,5.89l.33.24L1,5.72l.33-.26H.93L.8,5.07l-.13.39H.41l0,.09.22.17Z"/>
        </svg>
    )
}