import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../../Components/UI";
import { RecurringIncomePayment } from "../../../Utilities/Api";
import { Colors } from "../../../Utilities/Options/Colors";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import CategoryChip from "../../../Components/CategoryChip/CategoryChip";
import React, { useContext } from "react";
import { formatDate } from "../../../Utilities/Date";
import { CurrencyContext } from "../../../Utilities/Context/CurrencyContext";
import { useTranslation } from "react-i18next";

type Props = {
  expanded: boolean,
  onClick: () => void,
  onEditClick: () => void
} & RecurringIncomePayment

export default function Item({ expanded, onClick, onEditClick, ...props }: Props)
{
  const { t } = useTranslation("income");
  const currency = useContext(CurrencyContext);
  const c = Colors.find(({ name: n }) => n === props.color);
  const flagExpanded = expanded ? "expanded" : ""
  const className = `item ${flagExpanded}`;

  return (
    <div className={className}>
      <header onClick={onClick}>
        <div className="icon" style={{ 
            color: c?.color, 
            background: c?.background 
          }}>
          <FontAwesomeIcon icon={props.icon as IconProp} />
        </div>
        <h2>{props.name}</h2>
        <div className="cost">
          <div className="value">{props.amount}</div>
          <div className="detail">
            <div className="currency">{currency}</div>
            <div className="interval">/{t("month")}</div>
          </div>
        </div>
        <div className="expandedIcon">
          <FontAwesomeIcon icon={expanded ? "chevron-up" : "chevron-down"} />
        </div>
      </header>
      <div className="detail">
        <div className="info">
          <InfoGroup label={t("paymentAmount")} value={props.amount.toString()} unit={currency} />
          <InfoGroup label={t("startDate")} value={formatDate(props.dateCreated)} />
          <div className="infoGroup infoGroupW2">
            {props.category !== null 
              ? <CategoryChip 
                  name={props.category.name} 
                  icon={props.category.icon} 
                  color={props.category.color} />
              : ""}
          </div>
        </div>
        <Button 
          onClick={onEditClick}
          icon={<FontAwesomeIcon icon="pen" />}
          color="primary"
          mode="secondary">
          {t("edit")}
        </Button>
      </div>
    </div>
  );
}

type InfoGroupProps = {
  label: string, 
  value?: string, 
  unit?: string,
  children?: React.ReactNode
}

const InfoGroup = ({ label, value, unit = "", children }: InfoGroupProps) => {
  return (
    <div className="infoGroup">
      <div className="label">{label}</div>
      <div className="value">
        {children}
        <div className="value">{value}</div>
        <div className="unit">{unit}</div>
      </div>
    </div>
  );
}