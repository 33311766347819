import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './index.scss';
import Login from './Pages/Login/Login';
import Summary from './Pages/Summary/Summary';
import Settings from './Pages/Settings/Settings';
import Subscriptions from './Pages/Subscriptions/Subscriptions';
import Income from './Pages/Income/Income';
import Error from './Pages/Error/Error';
import Page from './Components/Page/Page';
import reportWebVitals from './reportWebVitals';
import { AuthProtection } from './Utilities/Auth/AuthProtection'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Boards from './Pages/Boards/Boards';
import Categories from './Pages/Categories/Categories';
import Debt from './Pages/Debt/Debt';
import { far } from '@fortawesome/free-regular-svg-icons';
import Payments from './Pages/Payments/Payments';
import { AcceptInvite } from './Utilities/AcceptInvite/AcceptInvite';

import "./Utilities/Locale/i18n";

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/serviceWorker.js');
}

library.add(fas);
library.add(far);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/new-account" element={<Login registration={true} />} />

        <Route element={<AuthProtection />}>
          <Route path="summary" element={<Page id="summary" title="summary"><Summary /></Page>} />
          <Route path="debt" element={<Page id="debt"><Debt /></Page>} />
          <Route path="subscriptions" element={<Page id="subscriptions"><Subscriptions /></Page>} />
          <Route path="payments" element={<Page id="payments"><Payments /></Page>} />
          <Route path="income" element={<Page id="income"><Income /></Page>} />
          <Route path="categories" element={<Page id="categories"><Categories /></Page>} />
          <Route path="settings/board" element={<Page id="settings" title="settings"><Settings page="board"/></Page>} />
          <Route path="settings/user" element={<Page id="settings" title="settings"><Settings page="user"/></Page>} />
          <Route path="settings/view" element={<Page id="settings" title="settings"><Settings page="view"/></Page>} />
          <Route path="settings/about" element={<Page id="settings" title="settings"><Settings page="about"/></Page>} />
          <Route path="boards" element={<Page id="boards"><Boards /></Page>} />
          <Route path="accept-invite/:code" element={<AcceptInvite />} />

          <Route path="logout" element={<Navigate to="/login" replace />} />
        </Route>

        <Route index path="/" element={<Navigate to="/summary" replace />} />
        <Route path="*" element={<Error code="404" />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
