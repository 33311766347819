import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Input, Button, LoaderIcon, Spinner } from '../../../../Components/UI';
import { Errors, FormError, useChangePasswordApi } from '../../../../Utilities/Api';
import { useGlobalSuccess } from '../../../../Components/GlobalSuccess/GlobalSuccess';
import { useTranslation } from 'react-i18next';

export type Form = {
  passwordOld: string
  passwordNew: string,
  passwordNewRepeat: string
};

const defaultValues = {
    passwordOld: "",
    passwordNew: "",
    passwordNewRepeat: ""
};

export default function ChangePassword()
{
  const { t } = useTranslation("settings");
  const { setSuccess } = useGlobalSuccess();    
  const {send, loading} = useChangePasswordApi();
  const [form, setForm] = useState<Form>(defaultValues);
  const [errors, setErrors] = useState<Errors>([]);

  const updateForm = (newValues: Partial<Form>) => {
    setForm((v: any) => ({ ...v, ...newValues }));
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if(form.passwordNew !== form.passwordNewRepeat)
    {
      setErrors([{ field: "passwordNewRepeat", message: "changePassword-passwordNewRepeat-mismatch" }]);
      return;
    }

    send({ 
      passwordOld: form.passwordOld,
      passwordNew: form.passwordNew
    })
      .then(() => {
        setForm(defaultValues);
        setErrors([]);
        setSuccess("check", "Password changed.");
      })
      .catch(reason => {
        if(reason.errors)
          setErrors(reason.errors);
      });
  };

  return (
    <div className="section">
        <h3>{t("user.password.title")}</h3>
        <form onSubmit={handleSubmit}>
            <div className="formGroup">
                <Input
                    type='password'
                    placeholder={t("user.password.currentPassword") || ""}
                    value={form.passwordOld}
                    onChange={e => updateForm({ passwordOld: e.target.value })} />
                <FormError field="passwordOld" errors={errors} />
            </div>
            <div className="formGroup">
                <Input 
                    type='password'
                    placeholder={t("user.password.newPassword") || ""}
                    value={form.passwordNew}
                    onChange={e => updateForm({ passwordNew: e.target.value })} />
                <FormError field="passwordNew" errors={errors} />
            </div>
            <div className="formGroup">
                <Input 
                    type='password'
                    placeholder={t("user.password.repeatNewPassword") || ""}
                    value={form.passwordNewRepeat}
                    onChange={e => updateForm({ passwordNewRepeat: e.target.value })} />
                <FormError field="passwordNewRepeat" errors={errors} />
            </div>
            <Button icon={loading ? <Spinner><LoaderIcon color='white'/></Spinner> : <FontAwesomeIcon icon="arrow-right" />}>{t("user.password.submit")}</Button>
        </form>
    </div>
  );
}
