import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal as BaseModal } from "../../../../Components/Modal/Modal";
import { useEffect, useState } from "react";
import { useGenerateBoardInviteApi } from "../../../../Utilities/Api";
import { LoaderIcon, Spinner } from "../../../../Components/UI";
import { INVITE_LINK_URL } from "../../../../Utilities/Constants";
import { useTranslation } from "react-i18next";

type Props = {
  visible: boolean
  onClose: () => void
}

export default function InviteModal({ visible, onClose }: Props)
{
  const { t } = useTranslation("settings");
  const [link, setLink] = useState<string>();
  const { send } = useGenerateBoardInviteApi();
  
  useEffect(() => {
    if(!visible) return;
    send()
      .then(response => setLink(INVITE_LINK_URL + response.code))
  }, [visible]);

  return (
    <BaseModal 
      visible={visible} 
      title={t("board.modal.invite.title")}
      onClose={onClose}>
        {link
          ? <a href={link}>{link}</a>
          : <Spinner><LoaderIcon color='white'/></Spinner>}
    </BaseModal>
  );
}