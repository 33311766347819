import './NavBar.scss';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

type Props = {
    activeItem: string,
    onMenuToggle: () => void,
    onMenuHide: () => void
}

export default function Nav({ activeItem, onMenuToggle, onMenuHide }: Props)
{
    const { t } = useTranslation("nav");
    
    const items = [
        {
            id: "summary",
            page: "/summary",
            icon: <FontAwesomeIcon icon="chart-line" />,
            text: t("summary")
        },
        {
            id: "debt",
            page: "/debt",
            icon: <FontAwesomeIcon icon="balance-scale-left" />,
            text: t("debt")
        },
        {
            id: "subscriptions",
            page: "/subscriptions",
            icon: <FontAwesomeIcon icon="calendar-days" />,
            text: t("subscriptions")
        },
        {
            id: "payments",
            page: "/payments",
            icon: <FontAwesomeIcon icon="right-left" />,
            text: t("payments")
        }
    ]

    const navigate = useNavigate();

    const handleItemClick = (index: number) => {
        navigate(items[index].page);
        onMenuHide();
    }

    return (
        <div className='navbar'>
            {items.map(({id, icon, text}, index) => (
                <div 
                    key={index}
                    onClick={() => handleItemClick(index)}
                    className={activeItem === id ? "active" : ""}>
                    {icon}
                    <div>{text}</div>
                </div>
            ))}
            <div 
                onClick={onMenuToggle}  
                className={!(["summary", "debt", "subscriptions", "payments"].includes(activeItem)) ? "active" : ""}>
                <FontAwesomeIcon icon="bars" />
                <div>More</div> 
            </div>
        </div>
    );
}
