import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Button } from '../Button/Button';
import './EmptyList.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @ts-ignore
import character from './character-grey.svg';

type Props = {
  text: string,
  action?: {
    text: string,
    icon?: IconProp,
    callback: () => void
  }
}

export const EmptyList = ({ text, action }: Props) => {
  return (
    <div className="emptyList">
        <header>
            <img src={character} alt="" className="character" />
            {text}
        </header>
        {action && <Button
            icon={action.icon && <FontAwesomeIcon icon={action.icon} />} 
            onClick={action.callback}>
                {action.text}
        </Button>}
    </div>
  );
}
