import { SummaryGroupSumup } from "../../../Utilities/Api";
import "./DisplaySumup.scss";
import { useContext } from "react";
import { CurrencyContext } from "../../../Utilities/Context/CurrencyContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

type Props = SummaryGroupSumup

export default function DisplaySumupChart({ data }: Props)
{
  const { t } = useTranslation("summary");
  const currency = useContext(CurrencyContext);
  
  const income = data.groups.find(group => group.label === "income")?.values[0] || 0;
  const expenses = data.groups.find(group => group.label === "expenses")?.values[0] || 0;

  const savingsSaved = income - expenses >= 0;

  const maxValue = Math.max(income, expenses);
  const heights = {
    income: income * 100 / maxValue,
    expenses: expenses * 100 / maxValue
  };

  return <div className="displaySumup">
    <div className="graph">
      <div className="bars">
        <div 
          className={"bar" + (heights.income < 35 ? " small" : "")} 
          style={{ height: heights.income + "%" }}>
          <div className="content">
            <div className="value">{income}</div> 
            <div className="unit">{currency}</div>
          </div>
          <div className="label">{t("displaySumup.income")}</div>
        </div>
        <div 
          className={"bar" + (heights.expenses < 35 ? " small" : "")}
          style={{ height: heights.expenses + "%" }}>
          <div className="content">
              <div className="value">{expenses}</div> 
              <div className="unit">{currency}</div>
            </div>
            <div className="label">{t("displaySumup.expenses")}</div>
          </div>
      </div>
    </div>
    <div className={"savings " + (savingsSaved ? "saved" : "loss")}>
      <div className="text">
        {savingsSaved
          ? <div className="title"> {t("displaySumup.saved.title")} <FontAwesomeIcon icon="thumbs-up" /></div>
          : <div className="title"> {t("displaySumup.loss.title")} <FontAwesomeIcon icon="face-sad-tear" /></div>}
        <div className="description">{savingsSaved ? t("displaySumup.saved.description") : t("displaySumup.loss.description")}</div>
      </div>
      <div className="amount">
        <div className="status">{savingsSaved ? t("displaySumup.saved.text") : t("displaySumup.loss.text")}</div>
        <div className="value">{Math.abs(expenses - income)}</div>
        <div className="unit">{currency}</div>
      </div>
    </div>
  </div>
};