import { Modal as BaseModal } from "../../../../Components/Modal/Modal";
import { useDeleteBoardApi } from "../../../../Utilities/Api";
import { Confirm } from "../../../../Components/Confirm/Confirm";
import { useGlobalError } from "../../../../Components/GlobalError/GlobalError";
import { useTranslation } from "react-i18next";

type Props = {
  visible: boolean
  onClose: (refresh: boolean) => void
}

export default function DeleteModal({ visible, onClose }: Props)
{
  const { t } = useTranslation("settings");
  const { setError } = useGlobalError();
  const { send } = useDeleteBoardApi();

  const handleProceed = () => {
    onClose(true);
    send({ })
      .then(() => onClose(true))
      .catch(reason => {
        if(reason.errors.includes("undeletable_board"))
          setError("trash", t("board.modal.delete.error"));
      });
  };

  return (
    <BaseModal 
      visible={visible} 
      title={t("board.modal.delete.title")}
      onClose={() => onClose(false)}>
        <Confirm 
          text={t("board.modal.delete.confirm")}
          proceed={{ text: t("board.modal.delete.submit"), icon: "skull", color: "danger" }}
          abort={{ text: t("board.modal.delete.abort") }}
          onProceed={handleProceed}
          onAbort={() => onClose(false)} />
    </BaseModal>
  );
}