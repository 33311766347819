import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './Confirm.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @ts-ignore
import character from './character-grey-sad.svg';
import { Button, ButtonColor } from '../UI';

type Props = {
  text: string,
  proceed: {
    text: string,
    icon?: IconProp,
    color?: ButtonColor
  },
  abort: {
    text: string
  },
  onProceed: () => void,
  onAbort: () => void
}

export const Confirm = ({ text, proceed, abort, onProceed, onAbort }: Props) => {
  return (
    <div className="confirm">
        <header>
          <img src={character} alt="" className="character" />
          {text}
        </header>
        <div className="actions">
          <Button onClick={onAbort} size="large" mode="secondary">
            {abort.text}
          </Button>
          <Button
            color={proceed.color || "primary"}
            icon={proceed.icon && <FontAwesomeIcon icon={proceed.icon} />} 
            onClick={onProceed}
            size="large">
            {proceed.text}
          </Button>
        </div>
    </div>
  );
}
