import { useState } from 'react';
import { RecurringPayment, useGetDebtApi } from '../../Utilities/Api';
import Item from './Item/Item'

import './Debt.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, EmptyList } from '../../Components/UI';
import { Modal } from './Modal/Modal';
import { CurrencyContext } from '../../Utilities/Context/CurrencyContext';
import { Debt as DebtType } from "../../Utilities/Api/Types/Debt";
import { ResolveModal } from './Modal/ResolveModal';
import { useTranslation } from 'react-i18next';

export default function Debt()
{
  const { t } = useTranslation("debt");
  const { response, refresh } = useGetDebtApi();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [resolveModalVisible, setResolveModalVisible] = useState<boolean>(false);
  const [resolveModal, setResolveModal] = useState<{ userBorrowed: string, userLended: string }>({ userBorrowed: "", userLended: "" });

  const handleNewClick = () => {
    setModalVisible(true);
  };

  const handleClose = (shouldRefresh: boolean) => {
    setResolveModalVisible(false);
    setModalVisible(false);
    if(shouldRefresh)
      refresh();
  };

  const handleItemClick = (item: DebtType) => {
    setResolveModal(item);
    setResolveModalVisible(true);
  };

  return (
    <div className="content">
      <CurrencyContext.Provider value={response.currency}>
        <header>
          <h2>{t('title')}</h2>
          <Button 
            size="small"
            icon={<FontAwesomeIcon icon="plus"/>} 
            onClick={handleNewClick} />
        </header>
        <div className="debt">
          <div className="container">
          {response.items.length === 0
              ? <EmptyList text={t('empty.text')} action={{ text: t('empty.action'), callback: handleNewClick}}/>
              : response.items.map((item, index) => 
                <Item 
                  key={index}
                  onClick={() => handleItemClick(item)}
                  userBorrowedAccount={response.members.includes(item.userBorrowed)}
                  userLendedAccount={response.members.includes(item.userLended)}
                  {...item} />
              )}
          </div>
        </div>
        <Modal visible={modalVisible} onClose={handleClose} />
        <ResolveModal visible={resolveModalVisible} onClose={handleClose} values={resolveModal} />
      </CurrencyContext.Provider>
    </div>
  );
}
