export const LoaderIcon = ({ color="#000", stroke=2, size=16 }) => {
    return (
        <svg 
            id="Layer_1" 
            data-name="Layer 1" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 16 16"
            style={{ width: size, height: size}}>
            <path 
                d="M8,3A5,5,0,1,1,3,8a4.79,4.79,0,0,1,.22-1.47" 
                style={{ 
                    fill: "none",
                    stroke: color,
                    strokeLinecap: "round",
                    strokeMiterlimit: 10,
                    strokeWidth: stroke + "px"
                }}/>
        </svg>
    )
}