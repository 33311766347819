import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import './Select.scss';

type Props = {
  value: string,
  options: Array<{ value: string, content: React.ReactNode }>
  onChange: (value: string) => void,
  optionsLayout?: "list" | "grid"
}

export const Select = ({ value, options, onChange, optionsLayout="list", ...props }: Props) => {
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const optionsVisible = open;
  const focus = focused ? "focus" : "";
  const optionsGridFlag = optionsLayout === "grid" ? "flag_optionsLayoutGrid" : "";
  const className = `select ${focus} ${optionsGridFlag}`;

  const handleClicked = () => {
    setOpen(!open);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
    setOpen(false);
  };

  const handleOptionClicked = (index: number) => {
    onChange(options[index].value);
  }

  const aciveIndex = options.findIndex(o => o.value === value);
  const activeOption = options[aciveIndex];

  return (
    <div
      onClick={handleClicked}
      onFocus={handleFocus}
      onBlur={handleBlur}
      tabIndex={0}
      className={className}>
      <div className="field" >
        {activeOption?.content || (<div />)}
        <div className="icon">
          {open 
            ? <FontAwesomeIcon icon="chevron-up" /> 
            : <FontAwesomeIcon icon="chevron-down" />}
        </div>
      </div>
      {optionsVisible && <div className="options">
        {options.map((option, index) => {
          const active = aciveIndex === index ? "active" : "";
          return (
            <div 
              className={`option ${active}`}
              key={index} 
              onClick={() => handleOptionClicked(index)}>
              {option.content}
            </div>
          )
        })}
      </div>}
    </div>
  );
}