import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Category } from "../../../Utilities/Api";
import CategoryChip from "../../../Components/CategoryChip/CategoryChip";

type Props = {
  onClick: () => void
} & Category


export default function Item({ onClick, name, icon, color }: Props)
{
  return (
    <div className="item" onClick={onClick}>
      <CategoryChip name={name} icon={icon} color={color} />
      <div className="moreIcon">
        <div className="label">detail</div>
        <FontAwesomeIcon icon="ellipsis-vertical" />
      </div>
    </div>
  );
}